const GlobalSetting = {
	SERVICE_URL: 'https://funliveapi.store', // 正式环境时  使用 
	WSS_URL: 'funliveapi.store', //ws地址
	TIMEOUT: 65000,
	PROJECT: {
		id: 'micro-service',
		logo: '/img/logo/logo.png',
		avatar: '/img/avatar/avatar.png',
		copyright: 'Copyright © 2019-2020',
		clientId: 'smartdata',
		clientSecret: 'smartdata'
	},
	EXTEND: {
		isMicroSystem: false, // 
		fixedHeader: false,
		sidebarLogo: true,
		moduleCache: true,
		flushDicts: [],
	},
	ActivationCode: '',
}

export default GlobalSetting