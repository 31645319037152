const zhLang = {
	langList: {
		list: ['English','中文','India', 'Việt Nam', 'Indonesia'],
	},
	footNameList: ['首頁', '活動', '錢包', '我的'],
	game: {
		name1: '期号',
		name2: '封盤',
		name3: '興奮即將開始',
		name4: '怎麼玩',
		name5: '冠軍',
		name6: '亞軍',
		name7: '季军',
		name8: '第四',
		name9: '第五',
		name10: '第六',
		name11: '第七',
		name12: '第八',
		name13: '餘額',
		name14: '投注',
		name15: '确定',
		name16: '取消',
		name17: '抽獎記錄',
		name18: '制勝趨勢',
		name19: '期號',
		name20: '中獎號碼',
		name21: '當前資金',
		name22: '中獎金額',
		name23: '點',
		name24: '錢',
		name25: '投注房間',
		name26: '等待抽獎結果',
		name27: '作废',
		name28: '無資料',
		name29: '當前比賽不可投注',
		name30: '請選擇投注選項',
		name31: '投注成功',
		name32: '冠亚和',
		name33: '特碼',
		name34: '龍虎',
		name35: '大小单双',
		name36: '日期選擇',
		name37: '自定義',
		name38: '開獎時間',
		name39: '自定義籌碼',
		name40: '請設置正確的值',
		name41: '確認訂單',
		name42: '玩法',
		name43: '賠率',
		name44: '金額',
		name45: '操作',
		name46: '第九',
		name47: '第十',
		name48: '余额不足，请充值!',
		menuList: ['歷史記錄', '投注記錄', '玩法'],
		trendTypeList: ['特碼', '大小单双'],
	},
	vip: {
		name: 'VIP',
		name1: '累計儲值金額',
		name2: '目標',
		name3: '級',
		name4: '升級限制',
		name5: '存款',
	},
	withdrawalList: {
		name: '提現訂單',
		name1: '金額',
		name2: '核准狀態',
		name3: '提幣時間',
		name4: '失敗原因',
		type0: '待審核',
		type1: '審核成功',
		type2: '審核失敗',
	},
	Login: {
		title: "歡迎回來",
		rem: '記住密碼',
		signIn: '登入',
		signUp: '註冊',
		userName: "用戶名",
		mobile: "手機",
		password: "登入密碼",
		code: "驗證碼",
	},
	register: {
		title: '建立帳戶',
		Registration: '加密註冊',
		Registration1: '密碼以去中心化方式存儲,遺失後無法找回。請記住您的密碼。 ',
		signIn: '登入',
		signUp: '註冊',
		password: '登入密碼',
		payPassword: '重複密碼',
		inviteCode: "邀請碼",
		code: '驗證碼',
		userName: "用戶名",
		name: '未收到',
		name1: '第二個',
		name2: '可以重新發送'
	},
	changePwd: {
		name: '更改密碼',
		name1: '新密碼',
		name2: '舊密碼',
		name3: '重複密碼',
		name4: '確認',
	},
	home: {
		name: '註冊錢包',
		name1: '購買貨幣',
		name2: "即時獎勵",
		name3: '註冊',
		name4: '去中心化',
		name5: '地址',
		name6: '平台',
		name7: '網站',
		name8: '課程',
		name9: '驗證',
		name10: "交換",
		name11: '錢包',
		name12: "專屬客服",
		name13: '貨幣',
		name14: '時代',
		name15: '來自',
		name16: '去中心化',
		name17: '金額',
		name18: '提現',
		name19: '線上',
		name20: '熱門推薦',
		menuList: ['活動消息', '錢包', '提現', '邀請'],
		gameTabs: ['全部', '彈珠'],
		navList: [{
				title: "公開透明",
				secList: [
					'我們捍衛每個人的公平權利',
					'區塊哈希值是基於去中心化的區塊鏈網路隨機生成的,任何人都可以隨時隨地在區塊鏈上查詢',
					'真正確保平台是一個公平,公正,公開的數位貨幣平台'
				]
			},
			{
				title: "誠信經營",
				secList: [
					'我們致力於確保客戶資訊財產的安全',
					'無需實名註冊,無個人資訊綁定',
					'無需充值押金,直接轉帳參與抽獎',
					'提款申請無需審核',
					'支付流量無限制',
					'平台資金池隨時可查,無需擔心平台資金破裂'
				]
			},
			{
				title: "中獎率高",
				secList: [
					'我們追求極簡流程/快速抽獎',
					'只需一個[去中心化]數位錢包,即可立即參與抽獎',
					'玩法,策略,裝備無限制,隨時隨地為所欲為',
					'中獎後10秒內自動到達,遊戲不間斷',
				]
			},
			{
				title: "專屬客戶服務",
				secList: [
					"一對一講解,高效率回覆。",
					"多種聯絡方式供您自由選擇。",
				]
			}
		],
		ruleList: [
			"03.若金額低於最低金額,則無法參加抽獎,參與金額不予退還",
			"04.若最低金額高於最低金額,則無法參加抽獎,並退還扣除雙倍手續費後的剩餘金額",
			'05.僅支持整數幣種參與抽獎,小數部分將自動忽略'
		],
		rule: "01.TRX僅支持參與人數",
		rule1: "數字硬幣",
		rule2: "02.USDT 參與人數僅支持",
		problemList: [{
				q: 'Q1:什麼是區塊哈希？',
				a: '區塊哈希值是一段資料的DNA。每個區塊的哈希值都是唯一的,隨機的,不可破解的。可以查看Google hash值的介紹'
			},
			{
				q: 'Q2:什麼是區塊鏈？',
				a: 'In essence, it is a database or a blockchain that stores information. It has the characteristics of non forgery, leaving traces in the whole process, traceability, openness and transparency, collective maintenance and so on.'
			},
			{
				q: "Q3:Centralized wallet? Decentralized wallet? Why can't the centralized wallet query the transfer?",
				a: "For example: your wallet address is a, and you transfer 10 usdts to address e, but you can only query the transfer between a service provider's random ledger address B (or C or D, etc.) and E by using the transaction hash value. Similarly, e cannot know whether these 10 usdts are transferred out by you, because e can only see that it is the account book address B that transfers in 10 usdts to him. This is why the centralized wallet cannot query the real transfer information on the blockchain."
			},
			{
				q: 'Q4:Why do I win or not win in a row?',
				a: "Firstly, the events that fail to win the prize for many times in a row are small probability events. Secondly, winning and not winning the prize are random events, and the frequency of random events will tend to a stable value. In the case of non iteration, the probability of things appearing is equal for everyone. In this case, everyone is fair. At the same time, random events will comply with the law of large numbers (under the condition that the test remains unchanged, repeat the test many times, and the frequency of random events is similar to its definition of the law of large numbers). With the increasing transfer frequency, the probability of winning or not winning will tend to a stable value. So please continue to transfer. As the transfer frequency increases, the total probability of winning the prize will increase."
			},
			{
				q: 'Q5:Why is it delayed?',
				a: "In order to ensure the security of funds, blockchain technology will conduct multiple accounting, comparison and confirmation. So the calculation speed is slow, but the most important thing is security."
			},
			{
				q: 'Q6:Why are there so many usdt type options when transferring currency? Which one should I choose?',
				a: "Many well-known public chains around the world support usdt transfer, and lucky hash is based on wave field trc20 technology. Therefore, the transfer address of lucky hash belongs to usdt-trc20. You should choose usdt-trc20; If you choose another type, lucky hash will not receive your usdt, which will cause losses to you and lucky hash."
			},
			{
				q: 'Q7:What should I do if I transfer the wrong address or select the wrong currency type? Can I get it back?',
				a: "For example, when you transfer money to us, you should choose usdt-trc20, but if you choose other types of usdt-omni or usdt-erc20, you will lose money, which cannot be retrieved."
			},
		],
	},
	billOrder: {
		name: '帳單訂單',
		name1: '貨幣',
		name2: '原始餘額',
		name3: '更改金額',
		name4: '更改後的金額',
		name5: '類型',
		name6: '日期',
		type0: '儲值佣金',
		type1: '註冊彩券',
		type2: '邀請佣金',
		type3: '提現與提現',
		type4: '提現失敗逆轉',
		type5: '贏',
		type6: '充值並進入',
		type7: '逆轉失敗的投注',
		type8: '活動獎勵',
		type9: '每日回水',
		type10: '投注',
		type11: '充值並進入',
	},
	guessNew: {
		name: '充值',
		name1: '記錄',
		name2: '大猜測雜湊值最後一位,0-7為小,8-f為大； 0, 2, 4, 6, 8, a, c, e 為偶數,1, 3, 5, 7, 9, b, d, f 為奇數。',
		name3: '餘額',
		name4: '確認訂單',
		name5: '投注選項',
		name6: '點選輸入投注金額',
		name7: '總計',
		name8: '確定',
		name9: '打賭',
		name10: '特點',
		name11: '圓形',
		name12: '驗證',
		name13: '哈希值',
		name14: '意味著大',
		name15: '意味著小',
		name16: '平均奇數',
		name17: '平均偶數',
		numList: ['大', '小', '奇', '偶', '大奇', '大偶', '小奇', '小偶'],
		name18: '停止投注',
		name19: '投注成功',
		name20: '返回',
		name21: '結果詳細資料',
		name22: '區塊',
		name23: '投注',
		name24: '結果',
	},
	mine: {
		name: "我的",
		name1: "今日交易",
		name2: "今天獲利",
		name3: '活动',
		name4: "下屬成員",
		name5: '流水',
		name6: '關閉',
		name7: '邀请人数',
		name8: '奖励金额',
		name9: '投注金額',
		name10: '未完成的流程',
		name11: '累計儲值金額',
		name12: '目標',
		title: "活动公告",
		tabNameList: ['錢包', '提款', '團隊', '帳單訂單', '提款清單', '分享',
			'通知', '支付密碼', '退出'
		],
	},
	order: {
		name: "訂單",
		name1: "遊戲名稱",
		name0: '期號',
		name2: '訂單號',
		name3: '結果',
		name4: "投注",
		name5: "金額",
		name6: '贏',
		name7: '日期',
		name8: '大',
		name9: '小',
		name10: '奇數',
		name11: '偶數',
		name12: '大奇數',
		name13: '大偶數',
		name14: '小奇數',
		name15: '小偶數',
	},
	share: {
		name: '分享',
		Invite: '邀請您的聯絡人',
		Invite1: "或加好友並賺取獎勵",
		Refer: '推薦並賺取獎勵',
		Refer1: '分享您的推薦連結並開始賺錢',
		Refer2: '複製連結',
		name1: "邀請規則",
		name2: "請分享您的邀請鏈接,邀請更多的朋友來參與這個有趣的猜謎遊戲,體驗遊戲樂趣的同時,還可以躺著收錢！",
		name3: "你將得到:",
		name4: "LV1直接推薦用戶轉帳總額1%的佣金",
		name5: "LV2間接推薦抽取用戶轉帳總額0.6%的佣金",
		name6: "LV3間接推薦抽取用戶轉帳總額0.4%的佣金",
		name7: "如果你直接邀請了50個LV1用戶,並且你的50個LV1用戶也邀請了他們的50個好友參加遊戲,那麼你就有2500個LV2用戶。",
		name8: "2500 個 LV2 用戶同時邀請了 50 個朋友參加遊戲,所以你有 125,000 個 LV3 用戶。",
		name9: "如果每個人平均每天轉帳500 USDT,您可以獲得:",
		name10: "(50×500)×1%+(2500×500)×0.6%+(125000×500)×0.4%=257750USDT",
	},
	appDown: {
		name: "Apple",
		name1: "Android",
	},
	trend: {
		name: "走勢",
		name1: "最後10期",
		name2: "歷史統計",
		name3: "數字",
		name4: "字母",
		name5: "機率",
	},
	withdrawal: {
		name: '提款',
		name1: '金額',
		name2: "佣金提現",
		name3: "貨幣類型",
		name4: "提幣金額",
		name5: "地址(T開頭)",
		name6: "請輸入您的安全密碼",
		name7: "確認",
		name8: '設定支付密碼',
		name9: '輸入付款密碼',
		name10: '設定地址並設定付款密碼',
	},
	betting: {
		name: '儲值',
		name1: "訂單提交成功,請在五分鐘內完成付款",
		name2: '複製',
		name3: "已支付,驗證結果",
		name4: '我的餘額',
		name5: '提現',
		name6: '手動儲值',
		name7: '請教',
	},
	notify: {
		name: "通知",
		name1: "關閉",
	},
	team: {
		name: '團隊',
		name1: "總佣金",
		name2: '已完成',
		name3: '未完成',
		name4: "第一級",
		name5: "金額",
		name6: '比率',
		name7: "總賭注",
		name8: '团体',
		name9: "第二級",
		name10: "第三級",
	},
	lottery: {
		name: "彩票",
		name1: '規則',
		name2: "號碼",
		name3: '全部',
		name4: "計數",
		name5: '總計',
		name6: '贏了就付費',
		name7: '打賭',
		name8: '圓形',
		name9: '結果',
		name10: '最後一位數字',
		name11: "投注內容",
		name12: '狀態',
		name13: '驗證',
		tabList: ['路線圖', '所有賭注', '我的賭注'],
		name14: 'ShellHash.com',
		name15: "請登入錢包",
		name16: '好的',

		name17: '儲值',
		name18: '平衡',
		name19: "餘額不足,請儲值",

		rule: "Lottery game description",
		rule1: "1. The lottery is drawn every 180 seconds. It requires users to have a fast payment method, so currently the lottery only supports playing games using a wallet browser. (It is recommended that users use TronLink, imToken, TokenPocket browsers to log in to ",
		rule2: " to play!)",
		rule3: "2. Blockchain is a distributed ledger system. The bookkeeper of the blockchain system collects the newly generated transactions in the blockchain network, verifies the legitimacy of these transactions, then packs these transactions in a block, records them on the ledger as a new page, and then records the account Pages are broadcast to the entire blockchain network. TRON blocks are formed every 3 seconds and each block has a unique hash value! The lottery will be based on the 5-digit mantissa of a TRON block hash every 180 seconds!",
		rule4: "3. Users can view all TRON block hashes at https://tronscan.io/",
		rule5: "4. Users can make betting guesses on the 5 mantissas respectively! The minimum single bet amount is 10TRX!",
		rule6: "5. We will stop all users' betting behavior 30 seconds before each draw! That is, at the 150th second of each round, we will ban bets! Therefore, the user only has 150 seconds to place a bet. If you place a bet after 150 seconds, the bet will be considered void and the system will automatically refund your bet!",
		rule7: "0, 1, 2, 3, 4 are low; 5, 6, 7, 8, 9 are high.",
		rule8: "0, 2, 4, 6, 8 are even numbers; 1, 3, 5, 7, and 9 are odd numbers.",
		rule9: "High, Low, Even, Odd odds are 1.9, which means if you bet 100 and win, you will get 190 stake and profit. The numerical odds are 9.5, which means that if you bet 1, bet 100 and hit the jackpot, you will get your stake and profit of 950.",
	},
	guess: {
		name: "Trend",
		name1: "Game Rule",
		name2: "Win rate",
		name3: "Odds",
		name4: "Confirm",
		name5: "Win Times",
		name6: "Lose Times",
		name7: "Total Profit",
		name8: "Order Confirmation",
		name9: "Your betting item",
		name10: "Your betting currency",
		name11: "Your betting amount",
		name12: "Cancel",
		name13: "Ok",
		name14: "Please login Wallet",
		name15: "Recharge",
		name16: "Balance",
		name17: "Insufficient balance, please recharge",
		rule1: "Hash guessing is a new type of guessing smart contract based on the TRX public chain. Users getrewards by guessing the last character of the hash value generated during the transactionpayment process.",
		rule2: "1. The last character of the hash value will be (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, a, b, c, d, e, f)16 characters of which one of! The user can select one or more guess results, and the system will automatically calculate the reward multiple! If you choose only one result and win theprize, you can get up to 15 times the transfer amount as a reward! The more results you choose,the fewer reward multiples!",
		rule3: "2. After you select the guess result and confirm the order submission, the system will show you aTRX transfer address, and you need to pay any amount of TRX to this address.After the payment iscompleted,the hash value of the payment order will be generated immediately!",
		rule4: "3. When you have completed the payment, please return to ShellHash and click the paymentverification button. If you win, the smart contract will immediately return the winning amountto the TRX address you paid.",
		rule5: "4. It is recommended to use Tronlink, the official wallet of TRON, for guessing games, which canimprove your experience in the game.",
		rule6: "Important: Users complete TRX purchases on exchanges! In fact, the TRX purchased by the user is stored in the public address of the exchange before it leaves the exchange!",
		rule7: "When you transfer to ShellHash, what is transferred to ShellHash is the public address of the exchange!",
		rule8: "After winning the prize, ShellHash will automatically return the prize to the official account of the exchange!",
		rule9: "So the user will lose the bonus because of this!",
		rule10: "So be sure to transfer the TRX from the exchange to your wallet, and then bet with your wallet address! This way, the bonus will also be returned to your wallet in the same way!",
	},
};

export default zhLang