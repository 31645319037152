import Vue from 'vue'
import Vuex from 'vuex'
import cache from '@/common/js/cache'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		showAd: false,
		footIndex: 0, //控制底部菜单样式
		isShowFoot: false, //是否显示底部菜单栏
		baseBalance: 0, //
		trxBalance: 0,
		usdtBalance: 0,
		promotionBalance: 0,
		twName: '',
		twUrl: '',
		wsName: '',
		wsUrl: '',
		address: '', //钱包地址
		userName: '',
		langNum: cache.getLocalItem('updLangNum') ? Number(cache.getLocalItem('updLangNum')) : 0,
		isPc: false,
		// lottery ws 
		lotteryTimeOut: 180,
		lotteryDataList: [],
		lotteryStopBet: false,
		lotteryIsOpen: false,
		lotteryBet: '',
		// guess ws 结果
		txBlock: {},
		isOpenWs: false,
		// 初始化状态
		count: 20,
		onLine: 96,

		badge:0,
	},
	mutations: {
		updBadge(state, badge) {
			state.badge = badge;
		},
		updOnLine(state, onLine) {
			state.onLine = onLine;
		},
		// isOpenWs
		updIsOpenWs(state, isOpenWs) {
			state.isOpenWs = isOpenWs;
		},
		// lotteryBet
		updLotteryBet(state, lotteryBet) {
			state.lotteryBet = lotteryBet;
		},
		// lotteryTimeOut
		updLotteryTimeOut(state, lotteryTimeOut) {
			state.lotteryTimeOut = lotteryTimeOut;
		},
		// lotteryDataList
		updLotteryDataList(state, lotteryDataList) {
			state.lotteryDataList = lotteryDataList;
		},
		// lotteryStopBet
		updLotteryStopBet(state, lotteryStopBet) {
			state.lotteryStopBet = lotteryStopBet;
		},
		// lotteryIsOpen
		updLotteryIsOpen(state, lotteryIsOpen) {
			state.lotteryIsOpen = lotteryIsOpen;
		},
		// address
		updTxBlock(state, txBlock) {
			state.txBlock = txBlock;
		},
		// address
		updAddress(state, address) {
			state.address = address;
		},
		// trxBalanceaddress
		updTrxBalance(state, trxBalance) {
			state.trxBalance = trxBalance;
		},
		// isPc
		updUsdtBalance(state, usdtBalance) {
			state.usdtBalance = usdtBalance;
		},
		// isPc
		updIsPc(state, isPc) {
			state.isPc = isPc;
		},
		// langNum
		updLangNum(state, langNum) {
			state.langNum = langNum;
		},
		// twName
		updSTwNamel(state, twName) {
			state.twName = twName;
		},
		// twUrl
		updSTwUrl(state, twUrl) {
			state.twUrl = twUrl;
		},
		// wsName
		updWsName(state, wsName) {
			state.wsName = wsName;
		},
		// wsUrl
		updWsUrl(state, wsUrl) {
			state.wsUrl = wsUrl;
		},
		// showAd
		updShowAd(state, showAd) {
			state.showAd = showAd;
		},
		// promotionBalance
		updProBalance(state, promotionBalance) {
			state.promotionBalance = promotionBalance;
		},
		// baseBalance
		updBalance(state, baseBalance) {
			state.baseBalance = baseBalance;
		},
		// userName
		updUserName(state, userName) {
			state.userName = userName;
		},
		//footIndex
		updFootIndex(state, footIndex) {
			state.footIndex = footIndex;
		},
		// 底部菜单栏状态
		updFootSt(state, isShowFoot) {
			state.isShowFoot = isShowFoot;
		},
		// 处理状态
		updCount(state, payload) {
			state.count += payload;
		}
	},
	actions: {
		//onArr改变后的状态
		showFootIndex(context, param) {
			context.commit('updFootIndex', context.state.footIndex)
		},
		// 底部菜单栏改变后的状态
		showFootSt(context, param) {
			context.commit('updFootSt', context.state.isShowFoot)
		},
		// 提交改变后的状态
		increment(context, param) {
			context.commit('updCount', context.state.count) //提交改变后的state.count值
		},
	},
	modules: {}
})