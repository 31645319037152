import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// empty
const empty = (resolve) => {
	require(['@/views/empty.vue'], resolve)
}

// lottery
const lotteryIndex = (resolve) => {
	require(['@/views/Lottery/index.vue'], resolve)
}
// betting
const bettingIndex = (resolve) => {
	require(['@/views/Lottery/betting.vue'], resolve)
}


// sign in  up
const loginIndex = (resolve) => {
	require(['@/views/Login/loginIndex.vue'], resolve)
}
const register = () => import( /* webpackChunkName: "group-foo" */ '@/views/Login/regIndex.vue')

// Mine
const mineIndex = (resolve) => {
	require(['@/views/Mine/index.vue'], resolve)
	// require(['@/views/Lottery/index.vue'],resolve)
	// require(['@/views/Guess/index.vue'],resolve)
}
const appDown = (resolve) => {
	require(['@/views/Mine/appDown.vue'], resolve)
}
const notifyIndex = (resolve) => {
	require(['@/views/Mine/notifyIndex.vue'], resolve)
}
const notifyDetail = (resolve) => {
	require(['@/views/Mine/noticeDetail.vue'], resolve)
}
const userVipDetail = (resolve) => {
	require(['@/views/Mine/userVipDetail.vue'], resolve)
}
const changePwd = (resolve) => {
	require(['@/views/Mine/changePwd.vue'], resolve)
}
const courseIndex = (resolve) => {
	require(['@/views/Home/courseIndex.vue'], resolve)
}
// share
const shareIndex = (resolve) => {
	require(['@/views/Share/index.vue'], resolve)
}
// newGame
const gameIndex = (resolve) => {
	require(['@/views/Guess/newIndex.vue'], resolve)
}
const guessIndex = (resolve) => {
	require(['@/views/Guess/index_new.vue'], resolve)
}
// trend
const trend = (resolve) => {
	require(['@/views/Guess/trend.vue'], resolve)
}
// resultMask
const resultMask = (resolve) => {
	require(['@/views/Guess/resultMask.vue'], resolve)
}


// Home
// const homeIndex = () => import(/* webpackChunkName: "group-foo" */ '@/views/Home/index.vue')
const homeIndex = () => import( /* webpackChunkName: "group-foo" */ '@/views/Home/index_new.vue')
const activityIndex = (resolve) => {
	require(['@/views/Home/activity.vue'], resolve)
}
// withdrawal
const withdrawal = (resolve) => {
	require(['@/views/Home/withdrawal.vue'], resolve)
}
const orderIndex = (resolve) => {
	require(['@/views/Order/index.vue'], resolve)
}
const billIndex = (resolve) => {
	require(['@/views/Order/billIndex.vue'], resolve)
}
const withdrawalList = (resolve) => {
	require(['@/views/Order/WithdrawalList.vue'], resolve)
}
const teamIndex = (resolve) => {
	require(['@/views/Home/teamIndex.vue'], resolve)
}
const activetyIndex = (resolve) => {
	require(['@/views/Home/activetyIndex.vue'], resolve)
}


export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [{
			path: '/',
			name: 'empty',
			component: empty,
			meta: {
				title: 'empty'
			}
		},
		{
			path: '/activityIndex',
			name: 'activityIndex',
			component: activityIndex,
			meta: {
				title: 'activityIndex'
			}
		},
		{
			path: '/lotteryIndex',
			name: 'lotteryIndex',
			component: lotteryIndex,
			meta: {
				title: 'lotteryIndex'
			}
		},
		{
			path: '/notifyDetail',
			name: 'notifyDetail',
			component: notifyDetail,
			meta: {
				title: 'notifyDetail'
			}
		},
		{
			path: '/userVipDetail',
			name: 'userVipDetail',
			component: userVipDetail,
			meta: {
				title: 'userVipDetail'
			}
		},
		{
			path: '/changePwd',
			name: 'changePwd',
			component: changePwd,
			meta: {
				title: 'changePwd'
			}
		},
		{
			path: '/resultMask',
			name: 'resultMask',
			component: resultMask,
			meta: {
				title: 'resultMask'
			}
		},
		{
			path: '/bettingIndex',
			name: 'bettingIndex',
			component: bettingIndex,
			meta: {
				title: 'bettingIndex'
			}
		},
		{
			path: '/homeIndex',
			name: 'homeIndex',
			component: homeIndex,
			meta: {
				title: 'Home'
			}
		},
		{
			path: '/loginIndex',
			name: 'loginIndex',
			component: loginIndex,
			meta: {
				title: 'Sign Up'
			}
		},
		{
			path: '/empty',
			name: 'empty',
			component: empty,
			meta: {
				title: 'Sign Up'
			}
		},
		{
			path: '/register',
			name: 'register',
			component: register,
			meta: {
				title: 'Sign In'
			}
		},
		{
			path: '/shareIndex',
			name: 'shareIndex',
			component: shareIndex,
			meta: {
				title: 'Share'
			}
		},
		{
			path: '/guessIndex',
			name: 'guessIndex',
			component: guessIndex,
			meta: {
				title: 'guessIndex'
			}
		},
		{
			path: '/gameIndex',
			name: 'gameIndex',
			component: gameIndex,
			meta: {
				title: 'gameIndex'
			}

		},
		{
			path: '/trend',
			name: 'trend',
			component: trend,
			meta: {
				title: 'trend'
			}
		},
		{
			path: '/mineIndex',
			name: 'mineIndex',
			component: mineIndex,
			meta: {
				title: 'Mine'
			}
		},
		{
			path: '/appDown',
			name: 'appDown',
			component: appDown,
			meta: {
				title: 'appDown'
			}
		},
		{
			path: '/courseIndex',
			name: 'courseIndex',
			component: courseIndex,
			meta: {
				title: 'courseIndex'
			}
		},
		{
			path: '/notifyIndex',
			name: 'notifyIndex',
			component: notifyIndex,
			meta: {
				title: 'notifyIndex'
			}
		},
		{
			path: '/withdrawal',
			name: 'withdrawal',
			component: withdrawal,
			meta: {
				title: 'withdrawal'
			}
		},
		{
			path: '/orderIndex',
			name: 'orderIndex',
			component: orderIndex,
			meta: {
				title: 'orderIndex'
			}
		},
		{
			path: '/billIndex',
			name: 'billIndex',
			component: billIndex,
			meta: {
				title: 'billIndex'
			}
		},
		{
			path: '/withdrawalList',
			name: 'withdrawalList',
			component: withdrawalList,
			meta: {
				title: 'withdrawalList'
			}
		},
		{
			path: '/teamIndex',
			name: 'teamIndex',
			component: teamIndex,
			meta: {
				title: 'teamIndex'
			}
		},
		{
			path: '/activetyIndex',
			name: 'activetyIndex',
			component: activetyIndex,
			meta: {
				title: 'activetyIndex'
			}
		},
	]
})