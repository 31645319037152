<template>
	<div id="app" :class="{'pcVw':$store.state.isPc}">
		<router-view></router-view>
		<div class='flex footBox' v-if="$store.state.isShowFoot">
			<div class="footItem" v-for="(item,index) in footList" :key='index' @click="chooseTap(item,index)">
				<div class="tabItem" :class="{'on': index == $store.state.footIndex}">
					<span class="iconfont" :class="item.className"></span>
					<p class="flexCenter name">{{footNameList[index]}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "App",
		data() {
			return {
				// ws
				socketTask: '',


				isPC: false,
				footNameList: [],
				footList: [{
						name: 'Home',
						className: 'icon-home2',
						url: '/homeIndex',
					},
					{
						name: 'Activity',
						className: 'icon-liwuhuodong',
						url: '/activityIndex',
					},
					{
						name: 'Recharege',
						className: 'icon-chongzhi-',
						url: '/bettingIndex',
					},
					{
						name: 'Mine',
						className: 'icon-user',
						url: '/mineIndex'
					},
				],
			}
		},
		methods: {
			getPlantInfo() {
				let that = this;
				this.getRequest('/getPlatInfo').then(res => {
					// console.log(res)
					if (res.code == '0') {
						if (res.data.tgName && res.data.tgUrl) {
							this.$cache.setSionItem('tgName', res.data.tgName);
							this.$cache.setSionItem('tgUrl', res.data.tgUrl);
							// this.$store.commit('updSTwNamel',res.data.tgName);
							// this.$store.commit('updSTwUrl',res.data.tgUrl);
						}
						if (res.data.wsName && res.data.wsUrl) {
							this.$cache.setSionItem('wsName', res.data.wsName);
							this.$cache.setSionItem('wsUrl', res.data.wsUrl);
							// this.$store.commit('updWsName',res.data.wsName);
							// this.$store.commit('updWsUrl',res.data.wsUrl);
						}
					}
				})
			},
			//获取推送过来的消息 ws
			createdWebsoket() {
				let that = this;
				let ws = '';
				//let ws = 'wss://hashnoble.vip/ws/connetion';
				//let ws = 'wss://hashlucy.work/ws/connetion';
				// let urlPre = this.api.split(":");
				// if(urlPre[0] == 'http'){
				// 	ws = "ws:"+urlPre[1]+wsUrl;
				// }else{
				// 	ws = "wss:"+urlPre[1]+wsUrl;
				// }
				this.socketTask = new WebSocket(ws);
				//当websoket创建成功的时候触发的函数
				this.socketTask.onopen = function(res) {
					// console.log(res)
					that.$store.commit('updIsOpenWs', true)
				};
				//当websoket接收server信息的时候触发的函数
				this.socketTask.onmessage = function(res) {
					let jsonObj = JSON.parse(res.data)
					that.$store.commit('updTxBlock', jsonObj.txBlock)
					let obj = {
						blockNum: jsonObj.blockNum,
						address: '...' + jsonObj.tx.substr(-15, 15),
						time: jsonObj.timeOut
					}
					that.$store.state.lotteryDataList.unshift(Object.assign({}, obj))
					that.$store.commit('updLotteryTimeOut', Number(jsonObj.timeOut))
					that.$store.commit('updLotteryStopBet', jsonObj.stopBet)
					that.$store.commit('updLotteryIsOpen', jsonObj.isOpen)
					that.$store.commit('updLotteryBet', jsonObj.bet)
					// that.timeOut = Number(jsonObj.timeOut);
					// that.stopBet = jsonObj.stopBet
					// if(jsonObj.isOpen){
					// 	that.toOrderNum(jsonObj.bet)
					// }
				};
				//关闭
				this.socketTask.onclose = function() {
					that.$store.commit('updIsOpenWs', false)
					that.selfToast("断开连接，请重新连接", false);

				};
				//报错
				this.socketTask.onerror = function(e) {
					that.$store.commit('updIsOpenWs', false)
					that.selfToast("出错了，请重新连接", false);
				}
			},

			goPAGE() {
				if ((navigator.userAgent.match(
						/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					))) {
					// this.isPC = false
					this.$store.commit('updIsPc', false)
				} else {
					// this.isPC = true
					this.$store.commit('updIsPc', true)
				}
			},
			chooseTap(item, index) {
				this.$store.commit('updFootIndex', index);
				this.$store.commit('updFootSt', true);
				// if(index == 3 || index == 4){
				// 	if(!this.$cache.getSionItem('3') || this.$des.decryptFun(this.$cache.getSionItem('3')) != 'true'){
				// 		this.selfToast('please login',false,() => {
				// 			this.$store.commit('updFootSt',false);
				// 			this.$router.push('/loginIndex');
				// 		})
				// 		return;
				// 	}
				// }
				this.$router.push(item.url)
			},
			// getLange
			getLange() {
				switch (this.$store.state.langNum) {
					case 0:
						this.footNameList = this.$engLang.footNameList
						break;
					case 1:
						this.footNameList = this.$zhLang.footNameList
						break;
					case 2:
						this.footNameList = this.$indiaLang.footNameList
						break;
					case 3:
						this.footNameList = this.$vietnamLang.footNameList
						break;
					case 4:
						this.footNameList = this.$indonesiaLang.footNameList
						break;
				}
			},
		},
		mounted() {
			this.goPAGE();
			this.getLange();
			this.getPlantInfo();
			if (!this.$store.state.isOpenWs) {
				// this.createdWebsoket();
			}
		},
		watch: {
			"$store.state.langNum"(n, o) {
				if (n != o) {
					this.getLange();
				}
			},
			"$store.state.isOpenWs"(n, o) {
				if (!n) {
					// this.createdWebsoket();
				}
			},
		},
		beforeCreate() {
			let updLangNum = this.$cache.getLocalItem('updLangNum')
			if (updLangNum) {
				this.$store.commit('updLangNum', Number(updLangNum));
			} else {
				this.$cache.setLocalItem('updLangNum', 0)
			}
		}
	}
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #b0b0bc;
		background: rgb(27, 35, 84);
	}

	.footBox {
		position: fixed;
		width: 100%;
		height: vm(50);
		// height:50px;
		bottom: vm(-1);
		left: 0;
		justify-content: space-between;
		z-index: 10;
		background: #2a324e;
		border-radius: vm(20) vm(20) 0 0;

		.footItem {
			flex: 1;
			color: #747993;
			font-size: vm(13);

			.iconfont {
				font-size: vm(20);
			}
		}

		.on {
			color: #386df4;
		}
	}

	.pcVw {
		width: 100%;
		margin: auto !important;

		.footBox {
			width: $pcWidth;
			left: $pcFixedLeft;
			border-radius: 20px 20px 0 0;
			height: 50px;

			.footItem {
				padding-top: 10px;
				font-size: 13px;
				height: 50px;

				.iconfont {
					font-size: 20px;
				}
			}
		}
	}
</style>