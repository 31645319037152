import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// language
import engLang from '@/common/language/lang-eng.js'
Vue.prototype.$engLang = engLang;
import zhLang from '@/common/language/lang-zh.js'
Vue.prototype.$zhLang = zhLang;
import indiaLang from '@/common/language/lang-india.js'
Vue.prototype.$indiaLang = indiaLang;
import indonesiaLang from '@/common/language/lang-indonesia.js'
Vue.prototype.$indonesiaLang = indonesiaLang;
import vietnamLang from '@/common/language/lang-vietnam.js'
Vue.prototype.$vietnamLang = vietnamLang;


import '@/common/scss/index.scss';
// cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
// 加解密
// import des from '@/common/js/DES'
// Vue.prototype.$des = des;
/*使用ElementUI插件*/
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
// 全局自定义组件
import selfToast from '@/components/Toast'
Vue.use(selfToast)
// 全局过滤器
import * as filters from '@/common/js/filter'
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
	Vue.prototype['$' + key] = filters[key]
})
// 缓存的
import cache from '@/common/js/cache'
Vue.prototype.$cache = cache;
// 基本操作方法
import allTools from '@/common/js/tool'
Vue.prototype.$allTools = allTools;
// axios 请求
import {
	postRequest,
	getRequest,
	putRequest,
	deleteRequest
} from "./axios/index";
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.getRequest = getRequest;
//复制剪切板
import Clipboard from 'clipboard';
Vue.prototype.$Clipboard = Clipboard
// 生成二维码
import qrCode from 'qrcodejs2';
Vue.prototype.$qrCode = qrCode

// vant ui
import {
	CountDown,
	Field,
	Popup,
	Picker,
	Switch,
	Icon,
	RadioGroup,
	Radio,
	Calendar,
	Pagination,
	Empty,
	Popover,
	Swipe,
	SwipeItem,
	Step,
	Steps,
	Circle,
	NoticeBar,
	Progress,
	Notify
} from 'vant'
import 'vant/lib/index.css';
Vue.use(CountDown)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Switch)
Vue.use(Icon)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Calendar)
Vue.use(Pagination)
Vue.use(Empty)
Vue.use(Popover)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Circle)
Vue.use(NoticeBar)
Vue.use(Progress)
Vue.use(Notify)

Vue.config.productionTip = false

//引入nprogress
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' //这个样式必须引入
// 简单配置
NProgress.inc(0.2)
NProgress.configure({
	easing: 'ease',
	speed: 500,
	showSpinner: false
})

router.beforeEach((to, from, next) => {
	NProgress.start()
	next()
})

router.afterEach(() => {
	NProgress.done()
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')