// 印尼语
const indonesiaLang = {
	langList: {
		// list:['English'],
		list: ['English','中文','India', 'Việt Nam', 'Indonesia'],
	},
	footNameList: ['Rumah', 'Aktivitas', 'Wompet', 'Mine'],
	game: {
		name1: 'Tahap',
		name2: 'Cakram tertutup',
		name3: 'Kegembiraan akan segera dimulai',
		name4: 'Cara bermain',
		name5: 'Juara',
		name6: 'Juara kedua',
		name7: 'Ketiga',
		name8: 'Keempat',
		name9: 'Kelima',
		name10: 'Keenam',
		name11: 'Ketujuh',
		name12: 'Kedelapan',
		name13: 'Saldo',
		name14: 'Taruhan',
		name15: 'Yakin',
		name16: 'Batal',
		name17: 'Catatan lotere',
		name18: 'Tren kemenangan',
		name19: 'MASALAH',
		name20: 'Angka kemenangan',
		name21: 'Stok uang saat ini',
		name22: 'Jumlah kemenangan',
		name23: 'Poin',
		name24: 'Uang',
		name25: 'Taruhan pada ruangan',
		name26: 'Menunggu hasil lotere',
		name27: 'Buang',
		name28: 'Tidak ada data',
		name29: 'Permainan saat ini tidak tersedia untuk taruhan',
		name30: 'Silakan pilih opsi taruhan',
		name31: 'Taruhan berhasil',
		name32: 'Total',
		name33: 'Kode khusus',
		name34: 'Naga macan',
		name35: 'Tunggal dan Ganda',
		name36: 'Pemilihan tanggal',
		nama37: 'kebiasaan',
		name38: 'Waktu pembukaan lotere',
		name39: 'Chip khusus',
		name40: 'Harap tetapkan nilai yang benar',
		name41: 'Konfirmasikan pesanan',
		nama42: 'Gameplay',
		nama43: 'Kemungkinan',
		nama44: 'Jumlah',
		nama45: 'Operasi',
		name46: 'kesembilan',
		name47: 'kesepuluh',
		name48: 'Saldo tidak mencukupi, silakan isi ulang!',
		menuList: ['Riwayat', 'Catatan taruhan', 'Cara bermain'],
		trendTypeList: ['Kode khusus', 'Tunggal dan Ganda'],
	},
	vip: {
		name: 'VIP',
		name1: 'Jumlah Deposit Kumulatif',
		name2: 'Target',
		name3: 'Tingkat',
		name4: 'Batas Upgrade',
		name5: 'Deposit',
	},
	withdrawalList: {
		name: 'RútDaftar',
		name1: 'Jumlah uang',
		name2: 'Status persetujuan',
		name3: 'Waktu penarikan',
		name4: 'Alasan gagal',
		type0: 'Untuk diuji',
		type1: 'Audit berhasil',
		type2: 'Audit gagal',
	},
	billOrder: {
		name: 'Catatan tagihan',
		name1: 'Uang',
		name2: 'Balas asal',
		name3: 'Ubah jumlah',
		name4: 'Jumlah setelah perubahan',
		name5: 'Tipe',
		name6: 'Tarikh',
		type0: 'Ulangmuat komisi',
		type1: 'Daftar loteri',
		type2: 'Komisi undangan',
		type3: 'Mundur dan mundur',
		type4: 'Pembalikan kegagalan penarikan',
		type5: 'Menang',
		type6: 'Buka atas manual',
		type7: 'Balik taruhan gagal',
		type8: 'Hadiah peristiwa',
		type9: 'Air sehari kembali',
		type10: 'Bertaruhan',
		type11: 'Isi ulang dan masukan',
	},
	Login: {
		title: "Selamat datang kembali",
		rem: 'Ambil kata sandinya',
		signIn: 'Daftar masuk',
		signUp: 'Daftar',
		userName: "Username",
		mobile: "Nomor ponsel",
		password: "Sandi daftar masuk",
		code: "Lampir",
	},
	register: {
		title: "Buat Akun",
		registration: 'Registrasi terenkripsi',
		registration1: 'Kata sandi disimpan dengan cara yang ditentralkan dan tidak dapat dicapai setelah hilang. Silakan ingat kata sandi Anda. ',
		signIn: 'SiDaftar masuk',
		signUp: 'Daftar',
		password: "Sandi daftar masuk",
		payPassword: "Sandi daftar masuk",
		inviteCode: "Kode undangan",
		code: "Lampir",
		userName: "Nomor ponsel",
		name: 'Tidak diterima',
		name1: 'Ulangi',
		name2: 'Resend available'
	},
	changePwd: {
		name: 'Ubah Sandi',
		name1: 'Kata sandi baru',
		name2: 'Kata sandi lama',
		name3: 'Kata sandi pembayaran',
		name4: 'Konfirmasi',
	},
	home: {
		name: "Daftar Dompet",
		name1: "Beli Uang",
		name2: "Hadiah waktu nyata",
		name3: "register",
		name4: "Decentralisasi",
		name5: "alamat",
		name6: "platform",
		name7: "website",
		name8: "kursus",
		name9: "VERIFY",
		name10: "pertukaran",
		name11: "dompet",
		name12: "Layanan pelanggan eksklusif",
		name13: "valuta",
		name14: "waktu",
		name15: "dari",
		name16: "decentralisasi",
		name17: "jumlah",
		name18: 'Pengunduran uang',
		name19: 'onLine',
		name20: 'rekomendasi menarik',
		gameTab: ['semua', 'kelereng'],
		menuList: ['Tim', 'Wompet', 'Pengunduran', 'Undangan'],
		navList: [{
				title: "Kebukaan dan transparansi",
				secList: [
					"Kami mempertahankan hak semua orang untuk keadilan",
					"Nilai hash blok secara acak dihasilkan berdasarkan jaringan blockchain terdecentralisasi, dan siapapun dapat bertanya pada blockchain kapanpun, dimanapun",
					"Benar-benar memastikan bahwa platform adalah platform mata uang digital yang adil, adil dan terbuka",
				]
			},
			{
				title: "Manajemen integritas",
				secList: [
					"Kami berkomitmen untuk memastikan keamanan properti informasi pelanggan",
					"Tidak ada registrasi nama asli dan tidak ada ikatan informasi pribadi",
					"Tidak perlu memuat ulang deposit dan berpartisipasi di loteri dengan transfer langsung",
					"Revisi aplikasi mundur tidak diperlukan",
					"Tidak ada batasan pada aliran pembayaran",
					"Pool dana platform dapat diperiksa kapan saja, dan tidak perlu khawatir tentang kerusakan dana platform"
				]
			},
			{
				title: "Kadar menang tinggi",
				secList: [
					"Kami mengejar proses minimalis / loteri cepat",
					"Hanya dompet digital [descentralised] dan berpartisipasi dalam loteri segera",
					"Tidak ada batasan pada metode bermain, strategi dan peralatan, dan melakukan apapun yang Anda inginkan kapan saja, di mana pun",
					"Automatis tiba dalam 10 detik setelah memenangkan hadiah, dan permainan tidak terganggu",
				]
			},
			{
				title: "Layanan pelanggan eksklusif",
				secList: [
					"Jelaskan satu per satu dan jawab dengan efisien.",
					"Variasi informasi kontak untuk Anda memilih secara bebas.",
				]
			}
		],
		ruleList: [
			"03.Jika jumlah yang lebih rendah dari minimum, Anda tidak dapat berpartisipasi dalam gambar beruntung dan jumlah yang berpartisipasi tidak akan dikembalikan",
			"04.Jika jumlah minimal lebih tinggi dari jumlah minimal, Anda tidak dapat berpartisipasi dalam gambar beruntung dan mengembalikan jumlah yang tersisa setelah menentukan biaya pemegang ganda",
			"05.Only integer currency is supported to participate in the lucky draw, and the decimal part will be ignored automatically"
		],
		rule: "01.TRX jumlah peserta hanya mendukung ",
		rule1: "koin digital.",
		rule2: "02.USDT jumlah peserta hanya mendukung ",
		problemList: [{
				q: 'Q1：Apa itu blok hash?',
				a: 'Nilai hash blok adalah DNA dari sepotong data. Setiap nilai hash blok unik, acak dan tidak bisa dihancurkan. Anda dapat melihat perkenalan nilai hash Google'
			},
			{
				q: 'Q2：Apa itu blockchain?',
				a: 'Pada dasarnya, itu adalah database atau blockchain yang menyimpan informasi. Ini memiliki karakteristik bukan palsu, meninggalkan jejak di seluruh proses, pelacakan, terbuka dan transparensi, pemeliharaan kolektif dan sebagainya.'
			},
			{
				q: "Q3：Dompet pusat? Dompet dekentralisasi? Mengapa dompet yang ditentralisasi tidak dapat meminta transfernya?",
				a: "Contohnya: alamat dompet Anda adalah a, dan Anda memindahkan 10 usdts ke alamat e, tetapi Anda hanya dapat menanyakan transfer antara alamat buku acak penyedia layanan B (atau C atau D, dll.) dan E dengan menggunakan nilai hash transaksi. Sama seperti, e tidak tahu apakah 10 usdts ini dipindahkan oleh Anda, karena e hanya dapat melihat bahwa itu adalah alamat buku akaun B yang dipindahkan dalam 10 usdts kepadanya. Inilah sebabnya dompet yang ditentralisasi tidak dapat menanyakan informasi transfer nyata pada blockchain."
			},
			{
				q: 'Q4：Mengapa aku menang atau tidak menang berturut-turut?',
				a: "Pertama, peristiwa yang gagal memenangkan hadiah untuk banyak kali berturut-turut adalah peristiwa kemungkinan kecil. Kedua, menang dan tidak menang hadiah adalah peristiwa acak, dan frekuensi peristiwa acak akan cenderung untuk nilai stabil. Dalam kasus non-iteration, kemungkinan hal-hal muncul sama bagi semua orang. Dalam kasus ini, semua orang adil. Pada saat yang sama, peristiwa acak akan mematuhi hukum angka besar (dalam kondisi bahwa tes tetap tidak berubah, ulangi tes banyak kali, dan frekuensi peristiwa acak sama dengan definisi hukum angka besar). Dengan frekuensi transfer meningkat, kemungkinan menang atau tidak menang akan cenderung pada nilai yang stabil. Jadi tolong teruskan memindahkan. Ketika frekuensi transfer meningkat, keseluruhan kemungkinan menang hadiah akan meningkat."
			},
			{
				q: 'Q5：Mengapa tertunda?',
				a: "Untuk memastikan keamanan dana, teknologi blockchain akan melakukan banyak akuntansi, perbandingan dan konfirmasi. Jadi kecepatan perhitungan adalah lambat, tapi yang paling penting adalah keamanan."
			},
			{
				q: 'Q6：Mengapa ada begitu banyak pilihan tipe usdt ketika memindahkan uang? Yang mana yang harus saya pilih?',
				a: "Banyak rantai publik terkenal di seluruh dunia mendukung kita transfer, dan hash beruntung berdasarkan teknologi lapangan gelombang trc20. Oleh karena itu, alamat transfer hash beruntung milik usdt-trc20. Anda harus memilih usdt-trc20; Jika Anda memilih jenis lain, hash beruntung tidak akan menerima usdt Anda, yang akan menyebabkan kehilangan untuk Anda dan hash beruntung."
			},
			{
				q: 'Q7：Apa yang harus saya lakukan jika saya memindahkan alamat yang salah atau memilih tipe uang yang salah? Dapatkah saya mendapatkan kembali?',
				a: "Contohnya, ketika Anda memindahkan uang kepada kami, Anda harus memilih usdt-trc20, tetapi jika Anda memilih jenis lain usdt-omni atau usdt-erc20, Anda akan kehilangan uang, yang tidak dapat dicapai."
			},
		],
	},
	mine: {
		name: "Mine",
		name1: "Penjualan hari ini",
		name2: "Komisi hari ini",
		name3: "Aksi",
		name4: "Anggota bawah",
		name5: "mengalir ",
		name6: "Tutup",
		name7: "orang",
		name8: "penghargaan",
		name9: "jumlah",
		name10: 'air mengalir',
		name11: 'Jumlah Deposit Kumulatif',
		name12: 'Target',
		title: "Tim",
		tabNameList: ['Wompet', 'Pengunduran', 'Tim', 'Tagihan', 'RútDaftar', 'Bagi',
			'Notifikasi', 'Ubah Sandi', 'Logout'
		],
	},
	order: {
		name: "Perintah",
		name1: "ID",
		nama0: 'nomor terbitan',
		nama2: 'Nomor pesanan',
		nama3: 'hasil',
		nama4: "Taruhan",
		name5: "Jumlah",
		name6: "Win",
		name7: "Tarikh",
		name8: 'Besar',
		name9: 'Kecil',
		name10: 'Aneh',
		name11: 'Bahkan',
		name12: 'Besar-Aneh',
		name13: 'Besar-Bahkan',
		name14: 'Kecil-Aneh',
		name15: 'Kecil-Bahkan',
	},
	guessNew: {
		name: 'Isi ulang',
		name1: 'Rekam',
		name2: 'Tempat terakhir dari nilai tebakan besar, 0-7 Kecil, 8-f Besar; 0, 2, 4, 6, 8, a, c, e Bahkan, 1, 3, 5, 7, 9, b, d, f Aneh.',
		name3: 'Balance',
		name4: 'Konfirmasi Perintah',
		name5: 'Opsi Taruhan',
		name6: 'Harga',
		name7: 'Total',
		name8: 'Tentukan',
		name9: 'Taruhan',
		name10: "Fitur",
		name11: 'BULAT',
		name12: 'Verifikasi',
		name13: 'Nilai Hash',
		name14: 'mean Besar',
		name15: 'mean Kecil',
		name16: 'mean Aneh',
		name17: 'mean Bahkan',
		numList: ['Besar', 'Kecil', 'Aneh', 'Bahkan', 'Besar-Aneh', 'Besar-Bahkan', 'Kecil-Aneh', 'Kecil-Bahkan'],
		name18: 'Berhenti bertaruh',
		name19: 'Bertaruhan berhasil',
		name20: 'kembali',
		name21: 'Rincian Hasil',
		name22: 'Hash Mantissa',
		name23: 'Bertaruhan',
		name24: 'Rincian',
	},
	share: {
		name: "Bagi",
		Invite: "Panggil kontak Anda",
		Invite1: "atau Friends and Earn Awards",
		Refer: "Refer dan Gaji",
		Refer1: "Bagi hubungan referal Anda dan mulai mendapatkan",
		Refer2: "Salin Link",
		name1: "Aturan undangan",
		name2: "Silakan berbagi hubungan undangan Anda dan mengundang lebih banyak teman untuk berpartisipasi dalam permainan tebakan menyenangkan ini. Sementara mengalami kegembiraan permainan, Anda juga bisa berbaring dan mengumpulkan uang!",
		name3: "Anda akan mendapatkan:",
		name4: "LV1 secara langsung merekomendasikan komisi 1% dari jumlah total transfer pengguna",
		name5: "LV2 secara indirekt merekomendasikan komisi 0,6% dari jumlah total transfer pengguna",
		name6: "LV3 secara indirekt merekomendasikan komisi 0,4% dari jumlah total transfer pengguna",
		name7: "Jika Anda secara langsung mengundang 50 pengguna LV1, dan 50 pengguna LV1 Anda juga mengundang 50 teman mereka untuk berpartisipasi dalam permainan, maka Anda memiliki 2500 pengguna LV2.",
		name8: "2500 pengguna LV2 juga mengundang 50 teman mereka untuk berpartisipasi dalam permainan, jadi Anda memiliki 125.000 pengguna LV3.",
		name9: "Jika setiap orang memindahkan 500 USDT per hari secara rata-rata, Anda dapat:",
		name10: "(50×500)×1%+(2500×500)×0.6%+(125000×500)×0.4%=257750USDT",
	},
	appDown: {
		name: "Apple",
		name1: "Android",
	},
	trend: {
		name: "Trend",
		name1: "Terakhir 10",
		name2: "Statistik sejarah",
		name3: "NOMOR",
		name4: "SURAT",
		name5: "Kemungkinan",
	},
	withdrawal: {
		name: "Pengunduran",
		name1: "jumlah uang",
		name2: "Pengunduran komisi",
		name3: "Tipe Uang",
		name4: "Kwota",
		name5: "Alamat( Mulai dengan T)",
		name6: "Masukkan sandi keamanan Anda",
		name7: "Konfirmasi",
		name8: 'Tetapkan kata sandi pembayaran',
		name9: 'Masukkan kata sandi pembayaran',
		name10: 'Tetapkan alamat Dan Tetapkan kata sandi pembayaran',
	},
	betting: {
		name: "Bertaruhan",
		name1: "Perintah dihantar dengan sukses,silakan selesaikan pause dalam lima menit",
		name2: "Salin",
		name3: "Dibayar,hasil verifikasi",
		name4: 'Saldo Saya',
		name5: 'Penarikan',
		name6: 'Isi ulang manual',
		name7: 'Konsultasikan',
	},
	notify: {
		name: "Notifikasi",
		name1: "tutup",
	},
	team: {
		name: "Tim",
		name1: "Total Komisi",
		name2: "Diatur",
		name3: "Tidak diselesaikan",
		name4: "Tingkat pertama",
		name5: "Jumlah",
		name6: "Ratio",
		name7: "Total taruhan",
		name8: "Komis",
		name9: "Tingkat kedua",
		name10: "Tingkat ketiga",
	},
	lottery: {
		name: "Loteri",
		name1: "Peraturan",
		name2: "Nomor",
		name3: "Semua",
		name4: "PANTA",
		name5: "TOTAL",
		name6: "Bayar pada Win",
		name7: "Taruhan",
		name8: "BULAT",
		name9: "HASIL",
		name10: "DIGIT TERAKHIR",
		name11: "Konten Taruhan",
		name12: "Status",
		name13: "VERIFY",
		tabList: ['ROADMAP', 'Semua taruhan', 'Taruhanku'],
		name14: "Hashnoble.com",
		name15: "Silakan daftar Dompet",
		name16: "Ok",


		name17: "Isi ulang",
		name18: "Keseimbangan",
		name19: "Saldo yang tidak mencukupi, silakan isi ulang",

		rule: "Deskripsi permainan loteri",
		rule1: "1. Loteri digambar setiap 180 detik. Hal ini membutuhkan pengguna untuk memiliki metode pembayaran cepat, sehingga saat ini loteri hanya mendukung bermain permainan menggunakan browser dompet. (Direkomendasi pengguna menggunakan perambang TronLink, imToken, TokenPocket untuk log masuk ke",
		rule2: " untuk bermain!)",
		rule3: "2. Blockchain adalah sistem buku yang disebarkan. Penjaga buku dari sistem blockchain mengumpulkan transaksi yang baru dihasilkan di jaringan blockchain, mengkonfirmasi legitimitas transaksi ini, kemudian mengemas transaksi ini dalam blok, merekam mereka di buku sebagai halaman baru, dan kemudian merekam halaman rekening yang dihasilkan ke seluruh jaringan blockchain. Blok TRON terbentuk setiap 3 detik dan setiap blok memiliki nilai hash unik! Loteri akan berdasarkan mantisa 5 digit dari hash blok TRON setiap 180 detik!",
		rule4: "3. Pengguna dapat melihat semua hash blok TRON di https://tronscan.io/",
		rule5: "4. Pengguna bisa menebak pada 5 mantissas! Jumlah taruhan tunggal minimum adalah 10TRX!",
		rule6: "5. Kita akan menghentikan perilaku taruhan semua pengguna 30 detik sebelum setiap gambar! Itu adalah, pada detik ke-150 setiap ronde, kita akan melarang taruhan! Oleh karena itu, pengguna hanya punya 150 detik untuk menempatkan taruhan. Jika Anda menaruh taruhan setelah 150 detik, taruhan akan dianggap kosong dan sistem akan secara otomatis mengembalikan taruhan Anda!",
		rule7: "0, 1, 2, 3, 4 rendah; 5, 6, 7, 8, 9 tinggi.",
		rule8: "0, 2, 4, 6, 8 adalah bahkan angka; 1, 3, 5, 7, dan 9 adalah angka yang aneh.",
		rule9: "Tinggi, rendah, bahkan, peluang yang aneh adalah 1,9, yang berarti jika Anda bertaruh 100 dan menang, Anda akan mendapatkan 190 taruhan dan keuntungan. Kemungkinan numerik adalah 9,5, yang berarti jika Anda bertaruh 1, bertaruh 100 dan memukul jackpot, Anda akan mendapatkan saham dan keuntungan 950.",
	},
	guess: {
		name: "Trend",
		name1: "Aturan Permainan",
		name2: "Kadar kemenangan",
		name3: "Kemungkinan",
		name4: "Konfirmasi",
		name5: "Win Times",
		name6: "Lose Times",
		name7: "Total Profit",
		name8: "Konfirmasi Perintah",
		name9: "Item taruhan Anda",
		name10: "Uang taruhan Anda",
		name11: "Jumlah taruhanmu",
		name12: "Batalkan",
		name13: "Ok",
		name14: "Silakan daftar Dompet",
		name15: "Isi ulang",
		name16: "Keseimbangan",
		name17: "Saldo yang tidak mencukupi, silakan isi ulang",
		rule1: "Tebak Hash adalah tipe baru menebak kontrak pintar berdasarkan rantai publik TRX. Pengguna mendapatkan hadiah dengan menebak karakter terakhir dari nilai hash yang dihasilkan selama proses transaksi.",
		rule2: "1. Karakter terakhir dari nilai hash akan (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, a, b, c, d, e, f)16 karakter dari mana salah satu! Pengguna dapat memilih satu atau lebih hasil tebakan, dan sistem akan secara otomatis menghitung penghargaan ganda! Jika Anda memilih hanya satu hasil dan memenangkan hadiah, Anda dapat mendapatkan sampai 15 kali jumlah transfer sebagai hadiah! Semakin banyak hasil yang Anda pilih,semakin sedikit hadiah berkembang!",
		rule3: "2. Setelah Anda memilih hasil tebakan dan mengkonfirmasi pengiriman perintah, sistem akan menunjukkan alamat transfer aTRX, dan Anda harus membayar jumlah TRX apapun ke alamat ini. Setelah pembayaran selesai,nilai hash dari perintah pembayaran akan dihasilkan segera!",
		rule4: "3. Ketika Anda telah menyelesaikan pembayaran, silakan kembali ke Hashnoble dan klik tombol verifikasi pembayaran. Jika Anda menang, kontrak pintar akan segera mengembalikan jumlah pemenang ke alamat TRX yang Anda bayar.",
		rule5: "4. Direkomendasikan untuk menggunakan Tronlink, dompet resmi TRON, untuk menebak permainan, yang menambah pengalaman Anda dalam permainan.",
		rule6: "Penting: Pengguna menyelesaikan pembelian TRX pada pertukaran! Bahkan, TRX yang dibeli oleh pengguna disimpan di alamat publik pertukaran sebelum meninggalkan pertukaran!",
		rule7: "Ketika Anda memindahkan ke Hashnoble, apa yang dipindahkan ke Hashnoble adalah alamat publik pertukaran!",
		rule8: "Setelah memenangkan hadiah, Hashnoble akan otomatis mengembalikan hadiah ke rekening resmi pertukaran!",
		rule9: "Jadi pengguna akan kehilangan bonus karena ini!",
		rule10: "Jadi pastikan untuk memindahkan TRX dari pertukaran ke dompetmu, dan kemudian bertaruh dengan alamat dompetmu! Dengan cara ini, bonus juga akan dikembalikan ke dompetmu dengan cara yang sama!",
	},
};

export default indonesiaLang