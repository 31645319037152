import ToastComponent from './index.vue'

const selfToast = {};

// 注册Toast
selfToast.install = function(Vue) {
	// 生成一个Vue的子类
	// 同时这个子类也就是组件
	const ToastConstructor = Vue.extend(ToastComponent)
	// 生成一个该子类的实例
	const instance = new ToastConstructor();

	// 将这个实例挂载在我创建的div上
	// 并将此div加入全局挂载点内部
	instance.$mount(document.createElement('div'))
	document.body.appendChild(instance.$el)

	// 通过Vue的原型注册一个方法
	// 让所有实例共享这个方法
	Vue.prototype.selfToast = (msg, isSuccess,fn,duration) => {
		if(typeof msg == 'string'){
			instance.message = msg;
		}
		if(typeof isSuccess == 'boolean'){
			if(isSuccess){
				instance.showSuccess = true;
			}else{
				instance.showFail = true;
			}
		}
		let num = typeof duration == 'number'? duration : 2000
		setTimeout(() => {typeof duration == 'number'
			if(typeof fn == 'function'){
				fn();
			}
			instance.showFail = false;
			instance.showSuccess = false;
		}, num);
	}
}

export default selfToast
