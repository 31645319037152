// 一些基本的操作方法
// 获取数据类型
const getValType = function(value){
	let type = '';
	switch(Object.prototype.toString.call(value)){
		case '[object Object]':
			type = 'object'
			break;
		case '[object Array]':
			type = 'array'
			break;
		case '[object Undefind]':
			type = 'undefind'
			break;
		case '[object Date]':
			type = 'date'
			break;
	}
	return type
}
// 获取地址参数
const getQueryString = function(url,name){
	var reg = new RegExp('(^|&|/?)' + name + '=([^&|/?]*)(&|/?|$)', 'i')
	var r = url.substr(1).match(reg) 
	if (r != null) {
	  return r[2]
	}
	return null;
}
// 时间格式的操作
const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
	// 2021/12/09 17:09:38
}
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

// 输出
module.exports = {
	getValType:getValType,
	getQueryString:getQueryString,
	formatTime:formatTime
}

