// 印度语
const indiaLang = {
	langList: {
		// list:['English'],
		list: ['English','中文','India', 'Việt Nam', 'Indonesia'],
	},
	footNameList: ['घर', 'क्रियाकलाप', 'वालेट', 'मेरा'],
	game: {
		name1: 'स्टेज',
		name2: 'सीलबंद डिस्क',
		name3: 'उत्साह शुरू होने वाला है',
		name4: 'कैसे खेलें',
		name5: 'चैंपियन',
		name6: 'उपविजेता',
		name7: 'तीसरा',
		name8: 'चौथा',
		name9: 'पांचवां',
		name10: 'छठा',
		name11: 'सातवां',
		name12: 'आठवां',
		name13: 'शेष राशि',
		name14: 'सट्टेबाजी',
		name15: 'ज़रूर',
		name16: 'रद्द करें',
		name17: 'लॉटरी रिकॉर्ड',
		name18: 'जीतने का रुझान',
		name19: 'मुद्दा',
		name20: 'जीतने वाले नंबर',
		name21: 'वर्तमान बैंकरोल',
		name22: 'जीतने वाली राशि',
		name23: 'पॉइंट',
		name24: 'पैसा',
		name25: 'कमरे पर दांव',
		name26: 'लॉटरी के नतीजों का इंतज़ार',
		name27: 'छोड़ दें',
		name28: 'कोई डेटा नहीं',
		name29: 'मौजूदा खेल सट्टेबाजी के लिए उपलब्ध नहीं है',
		name30: 'कृपया सट्टेबाजी का विकल्प चुनें',
		name31: 'सफल दांव',
		name32: 'कुल',
		name33: 'विशेष कोड',
		name34: 'ड्रैगन टाइगर',
		name35: 'सिंगल और डबल',
		name36: 'तारीख का चयन',
		name37: 'कस्टम',
		name38: 'लॉटरी खुलने का समय',
		name39: 'कस्टम चिप्स',
		name40: 'कृपया सही मान सेट करें',
		name41: 'आदेश की पुष्टि',
		name42: 'खेल खेल',
		name43: 'ओड्ड',
		name44: 'मात्रा',
		name45: 'आपरेशन',
		name46: 'नौवां',
		name47: 'दसवां',
		name48:'शेष राशि अपर्याप्त है, कृपया समय पर रिचार्ज करें!',
		menuList: ['इतिहास', 'बेट रिकॉर्ड', 'कैसे खेलें'],
		trendTypeList: ['विशेष कोड', 'सिंगल और डबल'],
	},
	vip: {
		name: 'VIP',
		name1: 'क्यूमुलेटिव डेपोसिट मात्रा',
		name2: 'लक्ष्य',
		name3: 'स्तर',
		name4: 'अद्यतन सीमा',
		name5: 'डिपोस्ट',
	},
	withdrawalList: {
		name: 'हटाएँसूची',
		name1: 'पैसा की मात्रा',
		name2: 'स्वीकार स्थिति',
		name3: 'विचार समय',
		name4: 'असफल कारण',
		type0: 'पुनरावृत्ति करने के लिए',
		type1: 'ऑडिट सफल',
		type2: 'ऑडिट असफल',
	},
	Login: {
		title: "पीछे स्वागत है",
		rem: 'पासवर्ड याद रखें',
		signIn: 'चिह्नित करें',
		signUp: 'हस्ताक्षर करें',
		userName: "इमेल",
		mobile: "मोबाइल संख्या ",
		password: "लॉगइन पासवर्ड",
		code: "संलग्न करें",
	},
	billOrder: {
		name: 'बिलिंग रेकॉर्ड',
		name1: 'मुद्रा',
		name2: 'मौलिक',
		name3: 'मात्रा बदलें',
		name4: 'परिवर्तन के बाद मात्रा',
		name5: 'प्रकार',
		name6: 'दिनांक',
		type0: 'कमिशन फिर चार्ज करें',
		type1: 'रेजिस्टरी',
		type2: 'निमन्त्रणा कमिशन',
		type3: 'हटाएँ और हटाएँ',
		type4: 'प्रतिक्रिया विफल',
		type5: 'जीतै',
		type6: 'मैनुअल उप्पर खोलें',
		type7: 'विफल बेट का उलटाना',
		type8: 'घटना प्रतिफल',
		type9: 'रोज़ी पानी वापस',
		type10: 'बेटिंग',
		type11: 'पुनरार्ज और प्रविष्टि',
	},
	register: {
		title: "खाता बनाएँ",
		registration: 'एनक्रिप्टेड रिजिस्ट्रेशन',
		registration1: 'पासवर्ड डिसेंट्रेलाइज के रीति में भण्डार किया जाता है और इसे हराये के बाद प्राप्त नहीं किया जा सकता. कृपया अपना पासवर्ड याद रखें.',
		signIn: 'चिह्नित करें',
		signUp: 'हस्ताक्षर करें',
		password: "लॉगइन पासवर्ड",
		payPassword: "लॉगइन पासवर्ड",
		inviteCode: "कोड आमन्त्रित करें",
		code: "संलग्न करें",
		userName: "मोबाइल संख्या ",
		name: 'प्राप्त नहीं है',
		name1: 'सेकंड',
		name2: 'उपलब्ध फिर भेजें'
	},
	changePwd: {
		name: 'पासवर्ड बदलें',
		name1: 'नया पासवर्ड',
		name2: 'पुराना पासवर्ड',
		name3: 'पैमेंट पासवर्ड',
		name4: 'पुष्टि करें',
	},
	home: {
		name: "वालेट रेजिस्टर करें",
		name1: "मुद्रा खरीद करें",
		name2: "रियल टाइम प्रतिज्ञा",
		name3: "रेजिस्टर",
		name4: "डेकेंट्रालाइजेशन",
		name5: "पता",
		name6: "प्लेटफ़ॉर्म",
		name7: "वेबसाइट",
		name8: "कोर्स",
		name9: "सत्यापित करें",
		name10: "बदलें",
		name11: "वालेट",
		name12: "एक्स्क्लुसिव ग्राहक सेवा",
		name13: "मुद्रा",
		name14: "समय",
		name15: "से",
		name16: "डेसेंट्रालाइजेशन",
		name17: "मात्रा",
		name18: 'हटाएँ',
		name19: 'पंक्ति',
		name20: 'लोकप्रिय अनुशंसाएँ',
		gameTabs: ['सभी', 'पत्थर'],
		menuList: ['टीम', 'वालेट', 'हटाएँ', 'साझेदारी'],
		navList: [{
				title: "खोलना तथा पारदर्शिता",
				secList: [
					"हम हर एक के अधिकार को सत्य से बचाते हैं",
					"ब्लॉक हैश मान अनियमित रूप से उत्पन्न होता है डिसेंट्रालाइज किया जा रहा है ब्लॉक चैन्चर नेटवर्क पर आधारित, और कोई भी ब्",
					"सच सुनिश्चित है कि प्लेटफ़ॉर्म एक साधारण, सिर्फ और डिजिटल मुद्रा प्लेटफ़ॉर्म है",
				]
			},
			{
				title: "पूर्णता प्रबंधन",
				secList: [
					"हम ग्राहकों के जानकारी गुण की सुरक्षा सुरक्षित करने के लिए आवश्यक हैं",
					"कोई वास्तविक name रिजिस्ट्रेशन नहीं और कोई व्यक्तिगत जानकारी बाइंडिंग नहीं",
					"सीधा स्थानान्तरण द्वारा लोटेरी में सहभागी करने की जरूरत नहीं है",
					"अनुप्रयोग पुनरावृत्ति आवश्यक नहीं है",
					"पैसा प्रवाह पर कोई सीमा नहीं",
					"प्लेटफ़ॉन्ड फंड पुल कोई समय पर चेक किया जा सकता है, और प्लेटफ़ॉन्ड फंड के बारे में कोई चिंता करने की जरूरत नहीं है"
				]
			},
			{
				title: "उच्च जीति दर",
				secList: [
					"हम न्यूनतम प्रक्रिया का पीछा करते हैं / तेज लोटेरी",
					"बस एक डिजिटल वालेट और लटरी में तुरंत सहभागी हो",
					"खेलाने के पद्धति, तत्वदर्शिका और उपकरण के लिए कोई सीमा नहीं, और जो कुछ भी आप चाहते हैं, कहीं भी करें",
					"प्रतिज्ञा जीते हुए 10 सेकण्ड के बाद स्वचालित रूप से पहुँचें",
				]
			},
			{
				title: "एक्स्क्लुसिव ग्राहक सेवा",
				secList: [
					"एक-एक-एक स्पष्ट करो और प्रतिक्रियात्मक जवाब दें.",
					"आपके लिए स्वतन्त्र चुने के लिए एक विविध संपर्क जानकारी.",
				]
			}
		],
		ruleList: [
			"03.यदि मात्रा न्यूनतम से कम है, तो आप भाग्य ड्राइ में सहभागी नहीं कर सकते और सहभागी मात्रा फिर नहीं दिया जाएगा",
			"04.यदि न्यूनतम मात्रा न्यूनतम मात्रा से उच्च है, तो आप भाग्य ड्राउक में सहभागी नहीं कर सकते और बाकी मात्रा दोहरी हैंडलिंग फीज",
			"05.सिर्फ पूर्णांक मुद्रा को भाग्यशाली ड्राउन में सहभागी करने के लिए समर्थित है, और दशमलव भाग स्वचालित रूप से"
		],
		rule: "01.TRX सहभागी की संख्या केवल समर्थन करता है ",
		rule1: "डिजिटल कोइन्स.",
		rule2: "02.USDT सहभागी की संख्या केवल समर्थन करता है ",
		problemList: [{
				q: 'Q1：एक ब्लॉक हैश क्या है?',
				a: 'ब्लॉक हैश मूल्य डाटा का एक टुक्का DNA है. हर ब्लॉक हैश मूल्य अद्वितिय, अनियमित और अब्रेक करने योग्य है. आप गूगल हैश मूल्य का परिचय देख सकते हैं'
			},
			{
				q: 'Q2：क्या ब्लॉक-चेन्ज है?',
				a: 'मूल में, यह एक डाटाबेस या एक ब्लॉक चेन्ज है जो जानकारी भण्डार करता है. इसके पास गोष्टी हैं, पूरी प्रक्रिया में ट्रेस छोड़ देते हैं, अनुसार, खुलापन और पारदर्शिता, संग्रहित पारदर्शिता और इससे भ.'
			},
			{
				q: "Q3：केंद्रित वालेट? डिसेंट्रालाइज किया जा रहा है? क्यों सेंट्रालाइज नहीं किया जा सकता है वालेट?",
				a: "उदाहरण के लिए: आपका वालेट पता एक है, और आप १० usdts को e पता के लिए स्थानान्तरण करने के लिए, लेकिन आप सिर्फ सेवा प्रदायक के अनियमित लेजर पता B (या C या D, इत्यादि) के इसी तरह, e नहीं जानता कि ये 10 usdts आप के द्वारा बाहर स्थानान्तरित हैं, क्योंकि e सिर्फ देख सकता है कि यह खाता पुस्तिका पता B है जो 10 usdts में उस इसी कारण केंद्रीकरण किया गया वालेट ब्लॉक-चैन पर वास्तविक स्थानांतरण जानकारी क्वेरी नहीं कर सकता."
			},
			{
				q: 'Q4：मैं एक पंक्ति में क्यों जीता हूँ या नहीं जीता?',
				a: "प्रथम, घटनाओं जो पंक्ति में बहुत बार प्रतिज्ञा जीतने में असफल हैं, छोटे संभावना घटनाओं हैं. द्वितीय, प्रतिज्ञा जीता और नहीं जीता है अनियमित घटना हैं, और अनियमित घटनाओं की आवृत्ति स्थिर मूल्य पर स्थिर होगी. बिना पुनरावृत्ति के संभाव्यता में हर एक के लिए बराबर है. इस किस्म में, सभी सच्चा है। उसी समय, अनियमित घटनाओं बड़ी संख्याओं के व्यवस्था के अनुसरण करेंगे (जिस स्थिति में परीक्षा बदला नहीं रहे विस्तारित स्थानान्तरण फ्रिक्वेन्सी के साथ, जीते या जीते नहीं हैं, स्थिर मूल्य की संभावना होगी. तो कृपया स्थानान्तरण करने के लिए जारी रखें. जबकि स्थानान्तरण फ्रीक्वेन्सी बढ़ती है, प्रतिज्ञा जीते हुए कुल संभाव्यता बढ़ेगा."
			},
			{
				q: 'Q5：यह क्यों लड़का जाता है?',
				a: "फंडस की सुरक्षा सुरक्षित करने के लिए, ब्लॉकचेन टेकनोलोज़िकी बहुत हिसाब, तुलना और पुष्टि करने के लिए बहुत तो गणना गति धीमी है, लेकिन सबसे महत्वपूर्ण चीज़ सुरक्षा है."
			},
			{
				q: 'Q6：मुद्रा स्थानान्तरण किया जाता है? किसी को मुझे चुनाना चाहिए?',
				a: "दुनिया के आसपास बहुत ज्ञात सार्वजनिक सार्वजनिक संख्या हमारे स्थानान्तरण समर्थन करते हैं, और भाग्यशाली हैश हैश इसलिए, भाग्य हैश का स्थानान्तरण पता हमारे लिए है.dt-trc20. आपको usdt- trc20 चुनाना चाहिए; यदि आप दूसरे प्रकार चुनेंगे, तो भाग्य हैश अपने आपको प्राप्त नहीं करेगा, जो तुम्हारे लिए घाटा होगा और भाग्य हैश होगा."
			},
			{
				q: 'Q7：यदि मैं गलत पता स्थानान्तरण करें या गलत मुद्रा क़िस्म चुनें? मैं इसे वापस ले सकता हूँ?',
				a: "उदाहरण के लिए, जब आप हमारे लिए पैसा स्थानान्तरण करते हैं, आप हमें dt-trc20 चुनें, लेकिन यदि आप हमारे अन्य प्रकारों को चुनें या usdt-omni या usdt-erc20, आप पै."
			},
		],
	},
	guessNew: {
		name: 'पुनरावृत्ति',
		name1: 'रेकर्ड',
		name2: 'बड़ा अनुमान हैश मूल्य के अंतिम स्थान, 0-7 छोटा है, 8-f बड़ा है; 0, 2, 4, 6, 8, a, c, e समानैं, 1, 3, 5, 7, 9, b, d, f अलगैं.',
		name3: 'संतुलन',
		name4: 'क्रम पुष्टि करें',
		name5: 'बेटिंग विकल्प',
		name6: 'मूल्य',
		name7: 'कुल',
		name8: 'निर्धारित करें',
		name9: 'बेट',
		name10: "विशेषता",
		name11: 'राउन्ड',
		name12: 'सत्यापित करें',
		name13: 'हैश मान',
		name14: 'मध्यम बड़ा',
		name15: 'मध्यम छोटा',
		name16: 'मध्यम अलग',
		name17: 'मध्यम समान',
		numList: ['बड़ा', 'छोटा', 'अलग', 'समान', 'बड़ा-अलग', 'बड़ा-समान', 'छोटा-अलग', 'छोटा-समान'],
		name18: 'बीट बन्द करें',
		name19: 'बेटिंग सफल',
		name20: 'वापस',
		name21: 'परिणाम विवरण',
		name22: 'प्रसिद्ध बनाए',
		name23: 'बेटिंग',
		name24: 'परिणाम',
	},
	mine: {
		name: "मेरा",
		name1: "आज ट्रेडिंग",
		name2: "आज कमिशन",
		name3: "कार्य",
		name4: "सदस्य",
		name5: "प्रवाहित",
		name6: "बन्द करें",
		name7: "लोग",
		name8: "प्रतिज्ञा",
		name9: "मात्रा",
		name10: 'प्रवाह पानी',
		name11: 'क्यूमुलेटिव डेपोसिट मात्रा',
		name12: 'लक्ष्य',
		title: "टीम",
		tabNameList: ['वालेट', 'हटाएँ', 'टीम', 'बिल्ल', 'हटाएँसूची', 'साझेदारी', 'सूचना', 'पासवर्ड बदलें', 'लॉगआउट'],
	},
	order: {
		name: "क्रम",
		name1: "आईडी",
		name0: 'निर्गम संख्या',
		name2: 'ऑर्डर नंबर',
		name3: 'परिणाम',
		name4: "सट्टेबाजी",
		name5: "पैसा",
		name6: "जीत",
		name7: "दिनांक",
		name8: 'बड़ा',
		name9: 'छोटा',
		name10: 'अलग',
		name11: 'समान',
		name12: 'बड़ा-अलग',
		name13: 'बड़ा-समान',
		name14: 'छोटा-अलग',
		name15: 'छोटा-समान',
	},
	share: {
		name: "साझेदारी",
		Invite: "अपने संपर्कों को आमन्त्रित करें",
		Invite1: "या दोस्त और ऐर्न रिवार्ड",
		Refer: "प्रतीक्षा और आर्न प्रतीक्षा करें",
		Refer1: "अपने संदर्भ लिंक साझा करें और प्राप्त करने को शुरू करें",
		Refer2: "लिंक कॉपी करें",
		name1: "नियम",
		name2: "कृपया अपने आमन्त्रण लिंक को साझा करें और इस मज़ा अभिमान खेल में सहभागी करने के लिए अधिक दोस्तों को आमन्त्रणा करें खेल के आनन्द के अनुभव होते हैं, तुम भी लड़कर पैसा भेज सकते हैं!",
		name3: "आप मिलेंगे:",
		name4: "LV1 प्रयोक्ता के कुल स्थानान्तरण मात्रा के 1% का कमिशियन सिफारिस करता है",
		name5: "LV2 प्रयोक्ताओं के कुल स्थानान्तरण मात्रा में से 0.6% का कमिशियन सिफारिस करता है",
		name6: "LV3 प्रयोक्ताओं के कुल स्थानान्तरण मात्रा में से 0.4% का कमिशियन सिफारिस करता है",
		name7: "यदि आप 50 LV1 प्रयोक्ता को सीधे आमन्त्रित करे, और आपके 50 LV1 प्रयोक्ता भी अपने 50 दोस्तों को खेल में सहभागी करने के लिए आमन्त्रित करे, तब आपक.",
		name8: "2500 लीवी2 उपयोक्ताओं ने अपने दोस्तों में से 50 को भागी खेल में सहभागी करने के लिए आमन्त्रित किया, तो आपके पास 125,000 लीवी.",
		name9: "यदि हर कोई दिन में प्रति दिन 500 USDT स्थानान्तरण करे, तो आप प्राप्त कर सकते हैं:",
		name10: "(50×500)×1%+(2500×500)×0.6%+(125000×500)×0.4%=257750USDT",
	},
	appDown: {
		name: "एपल",
		name1: "एन्डोरोड सिस्टम",
	},
	trend: {
		name: "ट्रेंड",
		name1: "अंतिम 10",
		name2: "Historical statistics",
		name3: "संख्या",
		name4: "लेटर",
		name5: "The probability",
	},
	withdrawal: {
		name: "हटाएँ",
		name1: "पैसा की मात्रा",
		name2: "कमिशियन प्रतिक्रिया",
		name3: "मुद्रा प्रकार",
		name4: "क्वोटा",
		name5: "पता (T से शुरू करें)",
		name6: "अपना सुरक्षा पासवर्ड भरें",
		name7: "पुष्टि करें",
		name8: 'पैसा पासवर्ड नियत करें',
		name9: 'पैसा पासवर्ड भरें',
		name10: 'पता सेट करें और पैसा पासवर्ड नियत करें',
	},
	betting: {
		name: "बेटिंग",
		name1: "क्रम सफलतापूर्वक जमा किया जाता है,कृपया पांच मिनट में पूरा करें",
		name2: "नकल",
		name3: "पैदा किया, सत्यापन परिणाम",
		name4: 'मेरा शेष',
		name5: 'निकासी',
		name6: 'मैन्युअल रिचार्ज',
		name7: 'परामर्श',
	},
	notify: {
		name: "सूचना",
		name1: "बन्द करें",
	},
	team: {
		name: "टीम",
		name1: "कुल कमिशन",
		name2: "सेटिंग",
		name3: "निश्चित",
		name4: "प्रथम स्तर",
		name5: "मात्रा",
		name6: "अनुपात",
		name7: "कुल बेट",
		name8: "कमिशन",
		name9: "दूसरा स्तर",
		name10: "तीसरा स्तर",
	},
	lottery: {
		name: "लोटेरी",
		name1: "नियम",
		name2: "संख्या",
		name3: "सभी",
		name4: "गणना",
		name5: "संपूर्ण",
		name6: "विन पर पैसा",
		name7: "बेट",
		name8: "राउन्ड",
		name9: "परिणाम",
		name10: "पिछला डिगिट",
		name11: "बेटिंग सामग्री",
		name12: "स्थिति",
		name13: "सत्यापित करें",
		tabList: ['राउन्ड मैप', 'सभी बैट', 'मेरा बेट'],
		name14: "Hashnoble.com",
		name15: "कृपया लगइन वालेट",
		name16: "ठीक है",

		name17: "कृपया लगइन वालेट",
		name18: "संतुलन",
		name19: "अपर्याप्त संतुलन, कृपया रिचार्ज करें",

		rule: "लोटेरी खेल विवरण",
		rule1: "1. लोटेरी हर 180 सेकण्ड डाली जाती है. इसे प्रयोक्ताओं को तेज पैसा पद्धति होने की आवश्यकता है, तो वर्तमान लॉटरी केवल वालेट ब्राउज़र के प्रयोग से (इसे सिफारिस किया जाता है कि उपयोक्ताओं ने TronLink, imToken, TokenPocket ब्राउजर को लॉगइन करने के लिए प्रयोग करें",
		rule2: " खेलाने के लिए!)",
		rule3: "2.ब्लॉकचैन एक वितरित लेखर सिस्टम है. Name TRON ब्लॉक हर 3 सेकंड बनाए जाएँगे और हर ब्लॉक के पास एक अद्वितिय हैश मूल्य है! लोटेरी प्रत्येक 180 सेकंड के 5-अंक मैन्टिसा पर आधारित होगी!",
		rule4: "3. प्रयोक्ता सभी TRON ब्लॉक हैश देख सकते हैं https://tronscan.io/",
		rule5: "4. प्रयोक्ताओं को 5 मैन्टिसास पर बेटिंग अभिमान बना सकते हैं! न्यूनतम एकल बेट मात्रा 10TRX है!",
		rule6: "5.हम सभी उपयोक्ता के विश्वास का व्यवहार 30 सेकण्डों से पहले रोकेंगे! यह है कि प्रत्येक राउण्ड के 150वें सेकंड में, हम बाँधों को रोकेंगे! इसलिए, उपयोक्ता के पास सिर्फ 150 सेकण्ड है. यदि आप 150 सेकण्ड के बाद एक बेट रखते हैं, बेट खाली समझता है और तंत्र स्वचालित रूप से आपका बेट पुनर्वित करेगा!",
		rule7: "0, 1, 2, 3, 4 कम हैं; 5, 6, 7, 8, 9 उच्च हैं.",
		rule8: "0, 2, 4, 6, 8 संख्या भी हैं; 1, 3, 5, 7, and 9 विचित्र संख्या.",
		rule9: "उच्च, निम्न, चार, अद्भुत संभावना 1.9 हैं, जिसका अर्थ है कि यदि आप १०० पैदा करे और जीते हैं, तो आप 190 पैदा और लाभ पाएँगे। संख्यात्मक विकल्प 9.5 हैं, जिसका अर्थ है कि यदि आप १ मान लेते हैं, १०० मान लेते हैं, और जैक्पोट मान लेते हैं, तो आप अपने स्टेक और.",
	},
	guess: {
		name: "ट्रेंड",
		name1: "खेल नियम",
		name2: "जीन दर",
		name3: "अक्षर",
		name4: "पुष्टि करें",
		name5: "जीत समय",
		name6: "गोया समय",
		name7: "कुल प्रोफाइट",
		name8: "क्रम पुष्टिकरण",
		name9: "आपका बेटिंग वस्तु",
		name10: "आपकी बेटिंग मुद्रा",
		name11: "आपका बेटिंग मात्रा",
		name12: "रद्द करें",
		name13: "ठीक है",
		name14: "कृपया लगइन वालेट",
		name15: "फिर से दाम लगाना",
		name16: "संतुलन",
		name17: "अपर्याप्त संतुलन, कृपया रिचार्ज करें",
		rule1: "ट्रैक्स सार्वजनिक शंकेत पर आधारित स्मार्ट कंट्रांट का अनमानान्वित करने का नया प्रकार है. प्रयोक्ताओं को ट्रांसेक्शन पैलेशन प्रक्रिया के दौरान उत्पन्न हैश मूल्य के अंतिम अक्षर के अन्तिम अक",
		rule2: "1. हैश मूल्य का अंतिम अक्षर होगा (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, a, b, c, d, e, f) 16 अक्षरों में से एक! प्रयोक्ता एक या अधिक अनुमान परिणाम चुन सकता है, और तंत्र स्वचालित रूप से बदला बहुत ही गणना करेगा! यदि आप सिर्फ एक परिणाम चुनें और प्रतिज्ञा जीते हैं, तो आप 15 गुना स्थानान्तरण मात्रा के रूप में प्रतिज्ञा के रू जितने अधिक परिणाम आप चुनेंगे,कम प्रतिदान गुनेगी!",
		rule3: "2. आप अनुमान परिणाम चुने के बाद तथा क्रम प्रदान को पुष्टि करें, तंत्र आपको aTRX स्थानान्तरण पता दिखाएगा, और आपको इस पता में किसी मात्र पैसा पूरा होने के बाद, पैसा क्रम का हैश मूल्य तुरंत उत्पन्न होगा!",
		rule4: "3. जब आप पैसा को पूरा कर चुके हैं, कृपया हाश्नोब्ले में वापस आओ और पैसा परीक्षण बटन क्लिक करें. यदि आप जीते हैं, स्मार्ट कन्ट्रैक्ट तुरंत में जीते हैं, जिसके मात्रा आपने जीते हैं, ट्रैक्स पता के लिए व",
		rule5: "4. ट्रॉनलिंक, TRON के आधिकारिक वालेट का उपयोग करने के लिए सिफारिस किया जाता है, जो खेल में आपके अनुभव सुधार सकते हैं.",
		rule6: "महत्वपूर्ण: परिवर्तन पर उपयोक्ताओं ट्रैक्स खरीदा पूरा करें! वास्तव में, उपयोक्ता द्वारा खरीदी ट्रैक्स बदलाने के सार्वजनिक पता में भंडारित है!",
		rule7: "जब आप हाश्नोब्ल में स्थानान्तरण किया जाता है, तो हश्नोब्ल में स्थानान्तरण की सार्वजनिक पता है!",
		rule8: "प्रतिज्ञा जीते के बाद, हाश्नोब्ल स्वचालित रूप से प्रतिज्ञा के आधिकारिक खाते में लौटा देगा!",
		rule9: "तो इस कारण उपयोक्ता बोनास हराएगा!",
		rule10: "तो निश्चित हो कि TRX को आपके वालेट में से बदलने के लिए, और फिर अपने वालेट पता से बेट करें! इसी तरह, बोनुस भी तुम्हारे पाकीट में भी उसी तरह लौटाया जाएगा!",
	},
};

export default indiaLang