import axios from "axios";
import qs from "qs";
import router from '@/router/index'
import {
	Dialog,
	Toast
} from 'vant';
import cache from '@/common/js/cache'
import GlobalSetting from '@/config/index'
// 根据环境切换域名
let base = null
if (process.env.VUE_APP_ENV == 'development') {
	// base = '/api'
	base = GlobalSetting.SERVICE_URL;
} else if (process.env.VUE_APP_ENV == 'test') {
	base = ''
} else if (process.env.VUE_APP_ENV == 'production') {
	base = GlobalSetting.SERVICE_URL;
}
import icon from '@/assets/img/logo.png'
let loading;
//正在请求的数量
let requestCount = 0
//显示loading
const showLoading = () => {
	if (requestCount === 0 && !loading) {
		Toast({
			message: "Loading",
			icon: icon,
		})
	}
	requestCount++;
}
//隐藏loading
const hideLoading = () => {
	requestCount--
	if (requestCount == 0) {
		Toast.clear()
	}
}

//默认请求超时时间
axios.defaults.timeout = 10000;
//表示跨域请求时是否需要使用凭证（默认false）
axios.defaults.withCredentials = true;
//设置post请求头
axios.defaults.headers["Content-Type"] = "application/json;";
// axios.defaults.headers['Content-Type'] = 'application/json;';
//请求拦截
axios.interceptors.request.use(
	config => {
		// console.log(config)
		let isOnline = config.url.endsWith('getOnline')
		//如果存在token，则将token添加到每次请求之中..
		let token = cache.getSionItem('token');
		if (token) {
			config.headers.token = token
		}
		if (!isOnline) {
			showLoading()
		}
		config.params = {
			...config.params
		};
		if (config.method === 'get') { // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
			config.params = {
				...config.params,
				// t: new Date().getTime()
			}
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);
// 响应拦截
axios.interceptors.response.use(
	response => {
		let message = null;
		if (response.status == 200) {
			hideLoading()
			let res = response.data;
			if (res.code === '0') {

			} else if (res.code == '201' || res.code == '401') {
				Toast({
					message: res.errorMsg
				})
				setTimeout(() => {
					router.replace('/loginIndex');
				}, 1500)
			} else {
				Toast({
					message: res.errorMsg
				})
			}
			return Promise.resolve(response);
		} else {
			hideLoading()
			return Promise.reject(response);
		}
	},
	error => {
		hideLoading()
		//响应错误
		if (error.response && error.response.status) {
			const status = error.response.status
			let message = null;
			switch (status) {
				case 400:
					message = '请求错误';
					break;
				case 401:
					message = 'login error';
					break;
				case 201:
					message = 'login error';
					break;
				case 404:
					message = '请求地址出错';
					break;
				case 408:
					message = '请求超时';
					break;
				case 500:
					message = '服务器内部错误!';
					break;
				case 501:
					message = '服务未实现!';
					break;
				case 502:
					message = '网关错误!';
					break;
				case 503:
					message = '服务不可用!';
					break;
				case 504:
					message = '网关超时!';
					break;
				case 505:
					message = 'HTTP版本不受支持';
					break;
				default:
					message = '请求失败'
			}
			// Toast.clear()
			if (message != '') {
				Toast({
					message: message
				})
			}
			if (status == '401' || status == '201') {
				setTimeout(() => {
					router.replace('/loginIndex');
				})
			}
			return Promise.reject(error);
		}
	}
);


export const postRequest = (url, params) => {
	return new Promise((resolve, reject) => {
		axios({
				method: 'post',
				url: `${base}${url}`,
				data: params
			})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}
export const putRequest = (url, params) => {
	return new Promise((resolve, reject) => {
		axios({
				method: 'put',
				url: `${base}${url}`,
				data: params
			})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}
export const getRequest = (url, params) => {
	return new Promise((resolve, reject) => {
		axios({
				method: 'get',
				url: `${base}${url}`,
				params: params
			})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}
export const deleteRequest = (url, params) => {
	return new Promise((resolve, reject) => {
		axios({
				method: 'delete',
				url: `${base}${url}`,
				data: params
			})
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}