// 过滤器汇总
// 状态
const getStatusName = function(val) {
	let name = '';
	switch (val) {
		case 0:
			name = '失败'
			break
		case 1:
			name = '成功'
			break
		case 2:
			name = '禁用'
			break
		case 3:
			name = '废弃中'
			break
		case 4:
			name = '废弃成功'
			break
		case 5:
			name = '废弃失败'
			break
		case -1:
			name = '转冻结失败'
			break
	}
	return name
}

export {getStatusName}