// 越南语
const vietnamLang = {
	langList: {
		list: ['English','中文','India','Việt Nam','Indonesia'],
	},
	footNameList: ['Nhà', 'Hoạt động', 'Ví tiền', 'Của'],
	game: {
		name1: 'Giai đoạn',
		name2: 'Đĩa niêm phong',
		name3: 'Sự phấn khích sắp bắt đầu',
		name4: 'Cách chơi',
		name5: 'Nhà vô địch',
		name6: 'Á quân',
		name7: 'Thứ ba',
		name8: 'Thứ tư',
		name9: 'Thứ năm',
		name10: 'Thứ sáu',
		name11: 'Thứ bảy',
		name12: 'Thứ tám',
		name13: 'Số dư',
		name14: 'Cược',
		name15: 'Chắc chắn',
		name16: 'Hủy',
		name17: 'Kỷ lục xổ số',
		name18: 'Xu hướng chiến thắng',
		name19: 'VẤN ĐỀ',
		name20: 'Số trúng thưởng',
		name21: 'Số tiền hiện tại',
		name22: 'Số tiền trúng thưởng',
		name23: 'Điểm',
		name24: 'Tiền',
		name25: 'Đặt cược vào phòng',
		name26: 'Đang chờ kết quả xổ số',
		name27: 'Bỏ',
		name28: 'Không có dữ liệu',
		name29: 'Trò chơi hiện tại không khả dụng để đặt cược',
		name30: 'Vui lòng chọn tùy chọn đặt cược',
		name31: 'Đặt cược thành công',
		name32: 'Tổng',
		name33: 'Mã đặc biệt',
		name34: 'Rồng hổ',
		name35: 'Đơn và đôi',
		name36: 'Chọn ngày',
		name37: 'tùy chỉnh',
		name38: 'Thời gian mở xổ số',
		name39: 'Chip tùy chỉnh',
		name40: 'Vui lòng đặt giá trị chính xác',
		name41: 'Xác nhận đơn hàng',
		name42: "Chơi",
		name43: "Tỷ lệ cược",
		name44: "Số tiền",
		name45: "Hoạt động",
		name46: 'thứ chín',
		name47: 'thứ mười',
		name48: 'Số dư không đủ, vui lòng nạp tiền!',
		menuList: ['Lịch sử', 'Kỷ lục cược', 'Cách chơi'],
		trendTypeList: ['Mã đặc biệt', 'Đơn và đôi'],
	},
	vip: {
		name: 'VIP',
		name1: 'Tổng gởi',
		name2: 'Mục',
		name3: 'Mức',
		name4: 'Giới hạn nâng cấp',
		name5: 'Gửi',
	},
	withdrawalList: {
		name: 'Rút Danh sách',
		name1: 'Số lượng',
		name2: 'Trạng thái phê duyệt',
		name3: 'Thời gian rút tiền',
		name4: 'Nguyên nhân thất bại',
		type0: 'Đang được xem xét',
		type1: 'Kiểm toán thành công',
		type2: 'Kiểm toán thất bại',
	},
	billOrder: {
		name: 'Hồ sơ thanh toán',
		name1: 'Tiền',
		name2: 'Số dư ban đầu',
		name3: 'Số tiền thay đổi',
		name4: 'Số tiền sau khi thay đổi',
		name5: 'Loại',
		name6: 'Ngày',
		type0: 'Nạp tiền hoa hồng',
		type1: 'Đăng ký tiền thưởng',
		type2: 'Hoa hồng mời',
		type3: 'Rút tiền mặt',
		type4: 'Rút tiền thất bại.',
		type5: 'Thắng',
		type6: 'Chia tay.',
		type7: 'Cược thất bại',
		type8: 'Phần thưởng sự kiện',
		type9: 'Trở lại nước hàng ngày',
		type10: 'Đặt cược',
		type11: 'Nạp tiền vào tài khoản',
	},
	Login: {
		title: "Mừng trở lại",
		rem: 'Nhớ mật khẩu',
		signIn: 'Ký vào',
		signUp: 'Đăng ký đi.',
		userName: "Username",
		mobile: "Số điện thoại",
		password: "Nhập mật khẩu",
		code: "Comment",
	},
	register: {
		title: "Tạo tài khoản",
		registration: 'Mã hóa',
		registration1: 'Mật khẩu được cất giữ theo một cách chặt chẽ và không thể lấy lại sau khi mất nó. Xin hãy nhớ mật khẩu. ',
		signIn: 'Ký vào',
		signUp: 'Đăng ký đi.',
		password: "Nhập mật khẩu",
		payPassword: "Nhập mật khẩu",
		inviteCode: "Mã mời",
		code: "Comment",
		userName: "Số điện thoại",
		name: 'Chưa nhận',
		name1: 'giây',
		name2: 'Đặt lại'
	},
	changePwd: {
		name: 'Thay đổi mật khẩu',
		name1: 'Mật khẩu mới',
		name2: 'Mật khẩu cũ',
		name3: 'Mật khẩu thanh toán',
		name4: 'Xác nhận',
	},
	home: {
		name: "Ghi ví",
		name1: "Mua tiền tệ",
		name2: "Giải thưởng thời gian thực",
		name3: "ghi",
		name4: "Ngày",
		name5: "địa chỉ",
		name6: "bục",
		name7: "trang",
		name8: "Name",
		name9: "ĐKÝ",
		name10: "trao",
		name11: "ví",
		name12: "Dịch vụ độc quyền",
		name13: "tiền",
		name14: "Thời",
		name15: "từ",
		name16: "Ngày",
		name17: "mức",
		name18: 'Rút tiền',
		name19: 'Trực tuyến',
		name20: 'Khuyến nghị phổ biến',
		gameTabs: ['tất cả', 'viên bi'],
		menuList: ['đội', 'Ví tiền', 'Rút', 'Mời'],
		navList: [{
				title: "Mở và trong suốt",
				secList: [
					"Chúng tôi bảo vệ quyền lợi của mọi người",
					"The khối hashi được tạo ra ngẫu nhiên dựa trên hệ thống khoá chặt hơn, và ai cũng có thể truy vấn vào chuỗi chắn bất cứ lúc nào, bất cứ đâu.",
					"Hãy thật sự đảm bảo rằng nền tảng là một nền tảng tiền tệ số công bằng và mở rộng",
				]
			},
			{
				title: "Quản lý hoà",
				secList: [
					"Chúng tôi cam kết đảm bảo an to àn cho tài sản thông tin của khách hàng",
					"Không đăng ký tên thật và không ràng buộc thông tin cá nhân",
					"Không cần phải nạp lại tiền đặt cọc và tham gia xổ số bằng chuyển nhượng trực tiếp.",
					"Không cần xem xét yêu cầu rút lui",
					"Không giới hạn dòng chảy",
					"Chương trình bảo hiểm có thể được kiểm tra bất cứ lúc nào, và không cần phải lo về việc phá vỡ quỹ tổ chức."
				]
			},
			{
				title: "Giá thắng cao",
				secList: [
					"Chúng tôi theo đuổi thủ tục nhỏ",
					"Chỉ một cái ví kỹ thuật số và tham gia xổ số ngay lập tức",
					"Không có giới hạn về phương pháp, chiến lược và thiết bị, và làm bất cứ điều gì bạn muốn bất cứ lúc nào, bất cứ đâu.",
					"Đến tự động trong vòng mười giây sau khi thắng giải, và trò chơi không bị gián đoạn.",
				]
			},
			{
				title: "Dịch vụ độc quyền",
				secList: [
					"Giải thích trực tiếp và trả lời hiệu quả.",
					"Các thông tin liên lạc khác nhau để các bạn tự do chọn.",
				]
			}
		],
		ruleList: [
			"03.Nếu số tiền này thấp hơn mức tối thiểu, bạn không thể tham gia vào số lượng may mắn và số tiền tham gia sẽ không được trả lại.",
			"04.Nếu số tiền tối thiểu cao hơn mức tối thiểu, anh không thể tham gia vào việc rút tiền may mắn và hoàn lại số tiền còn lại sau khi rút phí xử lý hai lần",
			"05.Chỉ hỗ trợ tiền tệ số nguyên được hỗ trợ để tham gia vào việc rút may, và số thập phân sẽ bị bỏ qua tự động."
		],
		rule: "01.TRX số người tham gia chỉ hỗ trợ ",
		rule1: "kĩ thuật số.",
		rule2: "02.USDT số người tham dự chỉ hỗ trợ ",
		problemList: [{
				q: 'Q1：Đây là văn chương?',
				a: 'Khối thuốc lá là DNA của một mảnh dữ liệu. Mỗi khối cần sa là đặc biệt, ngẫu nhiên và không thể phá vỡ. Bạn có thể xem giới thiệu giá trị hash của Google'
			},
			{
				q: 'Q2：Cái gì là chốt chặn?',
				a: 'Bản chất, nó là một cơ sở dữ liệu hoặc một chuỗi tắc chứa thông tin. Bản chất của nó là không giả mạo, để lại dấu vết trong toàn bộ quá trình, lần theo dấu vết, lần theo dấu vết, mở và trong suốt, bảo trì tập thể, v.'
			},
			{
				q: "Q3：Ví bị tâm? Ví trung gian? Tại sao không thể đặt câu hỏi về việc chuyển nhượng?",
				a: "Ví dụ như: địa chỉ ví của bạn là một, và bạn chuyển mười dùng để đặt địa chỉ e, nhưng bạn chỉ có thể truy vấn việc chuyển giao dịch giữa địa chỉ s ố số mệnh của một nhà cung cấp dịch vụ B (hay C hay D, v. v.) và E bằng cách sử dụng mật giá của giao dịch này. Cũng vậy, e không thể biết liệu anh có chuyển số tiền này đi hay không, bởi vì anh chỉ có thể thấy rằng đó là địa chỉ tài khoản B mà anh ta chuyển khoản sau mười lần. Đây là lý do tại sao cái ví tập trung không thể truy vấn thông tin chuyển nhượng thực sự trên chuỗi tắc.."
			},
			{
				q: 'Q4：Tại sao tôi thắng hay không thắng liên tiếp?',
				a: "Thứ nhất, các sự kiện không thắng giải nhiều lần liên tiếp là các sự kiện xác suất nhỏ. Thứ hai, chiến thắng và không đạt giải là sự kiện ngẫu nhiên, và tần suất các sự kiện ngẫu nhiên sẽ dẫn đến một giá trị ổn định. Trong trường hợp lặp lại, khả năng xuất hiện của vật là bình đẳng với mọi người. Trong trường hợp này, mọi người đều công bằng. Đồng thời, các sự kiện ngẫu nhiên sẽ tuân theo luật của các con số lớn (với điều kiện thử vẫn không thay đổi, lặp lại các thử nghiệm nhiều lần, và tần số các sự kiện ngẫu nhiên giống với định nghĩa của nó về luật của số đông). Với tần số thuyên chuyển tăng, khả năng thắng thua hoặc không thắng sẽ có xu hướng với giá trị ổn định. Tiếp tục chuyển khoản. Khi tần số chuyển hàng tăng lên, tỉ lệ thắng giải sẽ tăng lên.."
			},
			{
				q: 'Q5：Sao trễ vậy?',
				a: "Để đảm bảo an ninh của quỹ, công nghệ hàng loạt sẽ được kiểm to án, so sánh và xác nhận. Vậy tốc độ tính chậm, nhưng quan trọng nhất là an ninh."
			},
			{
				q: 'Q6：Tại sao lúc chuyển tiền tệ lại có nhiều lựa chọn kiểu dùng nhất? Tôi nên chọn cái nào?',
				a: "Rất nhiều dây chuyền công cộng nổi tiếng khắp thế giới ủng hộ việc chuyển giao hàng qua nước, và may mắn has dựa trên công nghệ lãnh thổ sóng. Do đó, địa chỉ chuyển hàng của Has may mắn thuộc về nhánh-giá 20. Cô nên chọn chúng tôi t4t giá; Nếu bạn chọn loại khác, khoai tây may mắn sẽ không nhận được ust, nó sẽ gây tổn thất cho bạn và khoai tây may mắn."
			},
			{
				q: 'Q7：Tôi nên làm gì nếu tôi chuyển nhầm địa chỉ hay chọn nhầm loại tiền tệ? Tôi lấy lại được không?',
				a: "Ví dụ, khi anh chuyển tiền cho chúng tôi, anh nên chọn miền Bắc, nhưng nếu anh chọn dạng khác của ust-omin hay ust-erc20, anh sẽ mất tiền, không thể lấy lại được."
			},
		],
	},
	guessNew: {
		name: 'Lát',
		name1: 'Ghi',
		name2: 'The last place of the big đoán hash value, 0-7 is Nhỏ, 8-f is Lớn; 0, 2, 4, 6, 8, a, c, e Đôi, 1, 3, 5, 7, 9, b, d, f Lạ.',
		name3: 'Description',
		name4: 'Xác nhận trật tự',
		name5: 'Tùy chọn đặt cược',
		name6: 'Nguồn:',
		name7: 'Tổng',
		name8: 'Chọn',
		name9: 'Đặt',
		name10: "nét",
		name11: 'tròn',
		name12: 'xác',
		name13: 'Giá trị hash',
		name14: 'Chuẩn Lớn',
		name15: 'Chuẩn Nhỏ',
		name16: 'Chuẩn Lạ',
		name17: 'Chuẩn Đôi',
		numList: ['Lớn', 'Nhỏ', 'Lạ', 'Đôi', 'Lớn-Lạ', 'Lớn-Đôi', 'Nhỏ-Lạ', 'Nhỏ-Đôi'],
		name18: 'Dừng cược',
		name19: 'Đặt cược thành công',
		name20: 'Quay lại',
		name21: 'Chi tiết kết quả',
		name22: 'Đức Chúa trời',
		name23: 'Đặt',
		name24: 'Chi tiết',
	},
	mine: {
		name: "Của",
		name1: "Thương vụ hôm nay",
		name2: "Hôm nay Ủy ban",
		name3: "Hành",
		name4: "ủy viên",
		name5: "chảy ",
		name6: "Đóng",
		name7: "người",
		name8: "Giải",
		name9: "mức",
		name10: "Nước chảy",
		name11: 'Tổng gởi',
		name12: 'Mục',
		title: "Đội",
		tabNameList: ['Ví tiền', 'Rút', 'Đội', 'Bill.', 'Rút Danh sách', 'Chia', 'Thông', 'Thay đổi mật khẩu',
			'Đăng xuất'
		],
	},
	order: {
		name: "Thứ",
		name1: "ID",
		name0: 'số phát hành',
		name2: 'Mã đơn hàng',
		name3: 'kết quả',
		name4: "Cá cược",
		name5: "Số lượng",
		name6: "Thắng",
		name7: "Ngày",
		name8: 'Lớn',
		name9: 'Nhỏ',
		name10: 'Lạ',
		name11: 'Đôi',
		name12: 'Lớn-Lạ',
		name13: 'Lớn-Đôi',
		name14: 'Nhỏ-Lạ',
		name15: 'Nhỏ-Đôi',
	},
	share: {
		name: "Chia",
		Invite: "Mời bạn gặp",
		Invite1: "hay bạn bè và tai lộc",
		Refer: "Màu hồng",
		Refer1: "Chia sẻ liên kết của bạn và bắt đầu kiếm tiền",
		Refer2: "Chép liên kết",
		name1: "Luật mời",
		name2: "Xin hãy chia sẻ liên kết thư mời và mời thêm bạn bè tham gia trò chơi đoán vui vẻ này. Khi nếm trải niềm vui của trò chơi, bạn cũng có thể nằm xuống và thu tiền!",
		name3: "Anh sẽ có:",
		name4: "LV1 khuyên trực tiếp một Ủy ban 1. số lượng người dùng đã chuyển nhượng tổng thể",
		name5: "LV2 gián tiếp đề nghị một hoa hồng 0.6. của số lượng người dùng bị chuyển nhượng tổng thể",
		name6: "LV3 gián tiếp đề nghị một hoa hồng 0.4. của số lượng người dùng bị chuyển nhượng tổng thể",
		name7: "Nếu bạn đã mời trực tiếp những người dùng 50011, và những người dùng 50011 cũng đã mời thêm vài người bạn liên 50 để tham gia trò chơi, rồi bạn có 2500 LV2 người dùng.",
		name8: "Người dùng 2500 LV2 cũng đã mời 50 của bạn của họ tham gia trò chơi, nên bạn có người dùng 125,000 LV3.",
		name9: "Nếu mọi người chuyển 500 USDT mỗi ngày trên trung bình, bạn có thể lấy:",
		name10: "(50×500)×1%+(2500×500)×0.6%+(125000×500)×0.4%=257750USDT",
	},
	appDown: {
		name: "Apple",
		name1: "Android",
	},
	trend: {
		name: "Màu",
		name1: "Cuối 10",
		name2: "Dữ liệu lịch sử",
		name3: "Số",
		name4: "Chữ",
		name5: "Xác suất",
	},
	withdrawal: {
		name: "Rút",
		name1: "bao nhiêu tiền",
		name2: "Ngừng lại",
		name3: "kiểu tiền tệ",
		name4: "Trích",
		name5: "Địa chỉ (Bắt đầu với T)",
		name6: "Nhập mật khẩu an ninh",
		name7: "Xác nhận",
		name8: 'Đặt mật khẩu thanh toán',
		name9: 'Nhập mật khẩu thanh toán',
		name10: 'Đặt địa chỉ Và Đặt mật khẩu thanh toán',
	},
	betting: {
		name: "Đặt",
		name1: "Lệnh được gửi thành công, xin hoàn thành việc cung cấp trong vòng năm phút",
		name2: "Rõ",
		name3: "Kết quả kiểm tra",
		name4: 'Số dư của tôi',
		name5: 'Rút tiền',
		name6: 'Nạp tiền thủ công',
		name7: 'Tư vấn',
	},
	notify: {
		name: "Thông",
		name1: "đóng",
	},
	team: {
		name: "Đội",
		name1: "Tổng Ủy ban",
		name2: "Giải",
		name3: "Không",
		name4: "Tầng một",
		name5: "Nguồn",
		name6: "Khẩu",
		name7: "Tổng cược",
		name8: "Ủy ban",
		name9: "Tầng hai",
		name10: "Tầng ba",
	},
	lottery: {
		name: "Lottery",
		name1: "Quy",
		name2: "Số",
		name3: "Tất",
		name4: "ho",
		name5: "Tổng",
		name6: "Nộp bằng Win",
		name7: "Đặt",
		name8: "tròn",
		name9: "kết quả",
		name10: "cuối số",
		name11: "Mức đặt cược",
		name12: "Bang",
		name13: "ĐKÝ",
		tabList: ['ROADBLOCK', 'Đặt hết', 'Đặt cược'],
		name14: "Hashnoble.com",
		name15: "Đăng nhập Ví",
		name16: "Ok",
		name17: "Nạp điện",
		name18: "THĂNG BẰNG",
		name19: "Không đủ số dư, vui lòng sạc lại",

		rule: "Mô tả trò chơi",
		rule1: "1. Số xổ số được rút ra từng 180 giây. Nó yêu cầu người dùng có một phương pháp thanh to án nhanh, nên hiện tại số xổ số chỉ hỗ trợ chơi trò chơi bằng một trình duyệt ví. (Khuyên người dùng dùng Tron Link, imToken, TokenPocket để đăng nhập vào ",
		rule2: " để chơi)!",
		rule3: "2. Chặn dây là hệ thống sổ sách được phân phát. Người kế to án của hệ thống hàng loạt sưu tầm các giao dịch mới được tạo ra trong hệ thống hàng rào, kiểm tra sự hợp pháp của các giao dịch này, sau đó đóng gói các giao dịch này thành một khối, ghi chép chúng vào sổ sách như một trang mới, rồi ghi lại các trang tài khoản trang được phát cho toàn bộ mạng chuỗi đã bị tắc. Các khối TRON được hình thành mỗi giây và mỗi khối có một giá trị Has độc đáo! Cuộc số này sẽ dựng trên sách đáng sống của việc thuốc của cai quản dưới bán ngay vào 180 giây!",
		rule4: "3. Người dùng có thể xem mọi cản lệnh ở https://tronscan.io/",
		rule5: "4. Người dùng có thể đoán cá cược dựa vào hàng năm. Giá trị cá nhân tối thiểu là 10TRX!",
		rule6: "5. Chúng ta sẽ dừng hành vi cá cược của mọi người sử dụng 9s trước mỗi lần rút! Tức là, vào mười lăm giây trong mỗi hiệp, chúng ta sẽ cấm cá cược! Do đó, người dùng chỉ có 150 giây để đặt cược. Nếu bạn đặt cược sau 150 giây, cá sẽ bị coi là không và hệ thống sẽ tự động hoàn lại cuộc cá của bạn!",
		rule7: "0, 1, 2, 3, 4 là thấp; 5, 6, 7, 8, 9 là cao.",
		rule8: "0, 2, 4, 6, 8 là số chẵn; 1, 3, 5, 7, và 9 là số lẻ.",
		rule9: "High, Low, Even, Odd odds are 1.9, which means if you bet 100 and win, you will get 190 stake and profit. The numerical odds are 9.5, which means that if you bet 1, bet 100 and hit the jackpot, you will get your stake and profit of 950.",
	},
	guess: {
		name: "xu hướng",
		name1: "Nguyên tắc Trò chơi",
		name2: "Tỷ lệ thắng",
		name3: "Lợi",
		name4: "Xác nhận",
		name5: "thắng giờ",
		name6: "Mất thời gian",
		name7: "Lợi nhuận tổng",
		name8: "Xác nhận lệnh",
		name9: "Mục cá cược của bạn",
		name10: "Tiền cược của bạn",
		name11: "Giá trị cá cược",
		name12: "Thôi",
		name13: "Ok",
		name14: "Đăng nhập Ví",
		name15: "Nạp điện",
		name16: "THĂNG BẰNG",
		name17: "Không đủ số dư, vui lòng sạc lại",
		rule1: "Đức Chúa trời chỉ ra một dạng mới để đoán những hợp đồng thông minh dựa trên chuỗi công cộng TRX. Người dùng có phần thưởng bằng cách đoán ra tính cách cuối cùng của giá trị Has tạo ra trong quá trình chuyển động.",
		rule2: "1. Nhân vật cuối c ùng của văn lệnh sẽ là (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, a, b, c, d, e, f)16 các ký tự! Người dùng có thể chọn một hoặc nhiều kết quả dự đoán, và hệ thống sẽ tự động tính số phần thưởng đa! Nếu bạn chọn chỉ một kết quả và thắng giải, bạn có thể đạt tới tới tới tới tới tới tới một lăm lần số lượng chuyển nhượng như một phần thưởng! Càng chọn kết quả, càng nhiều phần thưởng!",
		rule3: "2. Sau khi bạn chọn kết quả dự đoán và xác nhận đơn hàng, hệ thống sẽ cho bạn thấy địa chỉ chuyển nhượng tất cả, và bạn cần phải trả bất cứ giá nào men đến địa chỉ này. Sau khi thanh toán xong, các giá trị cần thiết sẽ được tạo ra ngay lập tức!",
		rule4: "3. Sau khi thanh to án xong, hãy trở về Đức Chúa trời và nhắp vào nút Kiểm tra lương. Nếu anh thắng, hợp đồng thông minh sẽ ngay lập tức trả lại số tiền có được vào địa chỉ TRX mà anh đã trả.",
		rule5: "4. Nó được đề nghị dùng Tronlink, ví chính thức của tập lệnh TRON, để chơi trò đoán mò, để khuyếch trương kinh nghiệm của bạn trong trò chơi.",
		rule6: "Rất quan trọng: Người dùng mua cả bản giao dịch. Thực tế, bản tin TRX được mua bởi người dùng được lưu trong địa chỉ công cộng của cuộc trao đổi trước khi nó rời khỏi cuộc trao đổi!",
		rule7: "Khi cậu chuyển đến Đức Chúa trời, điều được giao cho Đức Chúa trời là địa chỉ công cộng của cuộc trao đổi!",
		rule8: "Sau khi giành giải thưởng, Đức Chúa trời sẽ tự động trả lại giải thưởng cho tài khoản chính thức của cuộc trao đổi!",
		rule9: "Vì thế người dùng sẽ mất tiền thưởng!",
		rule10: "Vì vậy hãy chắc chắn chuyển khẩu TA từ trao đổi sang ví, rồi đặt với địa chỉ ví của bạn! Bằng cách này, tiền thưởng cũng sẽ được trả lại trong ví theo cùng một cách!",
	},
};

export default vietnamLang