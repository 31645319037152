<template>
    <div>
      <div class="toastFixed flexCenter" v-if="showSuccess">
				<div class="toast success">
					<img src="../../assets/img/logo.png">
					<p>{{message}}</p>
				</div>
			</div>
			<div class="toastFixed flexCenter" v-if="showFail">
				<div class="toast flexCenter">
					<span>{{message}}</span>
				</div>
			</div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        showSuccess: false,
		showFail: false,
        message: "Loading"
      };
    }
  };
</script>

<style scoped lang="scss">
	.toastFixed{
		position:fixed;
		width:100%;
		height:100%;
		top:0;
		left:0;
		z-index:10000;
		background: rgba(255,255,255,0);
		.toast {
		  padding:10px 12px;
			min-width: 130px;
		  font-size:14px;
		  color: #fff;
		  border-radius:20px;
			.icon{
				font-size:18px;
				margin-right:5px
			}
			background:rgba(0,0,0,.6);
		}
		.success{
			flex-wrap: wrap;
			min-width:120px;
			height:120px;
			border-radius:12px;
			padding:15px 0;
			img{
				width:45px;
			}
			p{
				width:100%;
				margin-top:15px;
			}
		}
	
	}
  .isPc{
		.toastFixed{
			.toast {
			  padding:10px 12px;
				min-width: 130px;
			  font-size:14px;
			  border-radius:20px;
				.icon{
					font-size:18px;
					margin-right:5px
				}
			}
			.success{
				min-width:120px;
				height:120px;
				border-radius:12px;
				padding:15px 0;
				img{
					width:45px;
				}
				p{
					width:100%;
					margin-top:15px;
				}
			}
				
		}
	}
</style>
