const engLang = {
	langList: {
		// list:['English'],
		list: [ 'English','中文','India', 'Việt Nam', 'Indonesia'],
	},
	footNameList: ['Home', 'Activity', 'Wallet', 'Mine'],
	// footNameList:['Home','Hash Guess','Mine'],
	game: {
		name1: 'Stage',
		name2: 'Sealed disk',
		name3: 'Excitement is about to begin',
		name4: 'How to play',
		name5: '1st',
		name6: '2nd',
		name7: '3rd',
		name8: '4th',
		name9: '5th',
		name10: '6th',
		name11: '7th',
		name12: '8th',
		name13: 'Balance',
		name14: 'Betting',
		name15: 'Sure',
		name16: 'Cancel',
		name17: 'Lottery record',
		name18: 'Winning trend',
		name19: 'ISSUE',
		name20: 'Winning numbers',
		name21: 'Current bankroll ',
		name22: 'Winning amount',
		name23: 'Point',
		name24: 'Money',
		name25: 'Bet on room',
		name26: 'Waiting for the lottery results',
		name27: 'Discard',
		name28: 'No data',
		name29: 'The current game is not available for betting',
		name30: 'Please select a betting option',
		name31: 'Bet successful',
		name32: 'Total',
		name33: 'Special code',
		name34: 'Dragon tiger',
		name35: 'Single and Double',
		name36: 'Date selection',
		name37: 'custom',
		name38: 'Lottery opening time',
		name39: 'Custom chips',
		name40: 'Please set the correct value',
		name41: 'Confirm order',
		name42: 'Gameplay',
		name43: 'Odds',
		name44: 'Amount',
		name45: 'Operation',
		name46: '9th',
		name47: '10th',
		name48: 'The balance is insufficient,please recharge!',
		menuList: ['Historys', 'Bet record', 'How to play'],
		trendTypeList: ['Special code', 'Single and Double'],
	},
	vip: {
		name: 'VIP',
		name1: 'Cumulative Deposit Amount',
		name2: 'Target',
		name3: 'Level',
		name4: 'Upgrade Limition',
		name5: 'Deposit',
	},
	withdrawalList: {
		name: 'withdrawalOrder',
		name1: 'Amount of money',
		name2: 'Approval status',
		name3: 'Withdrawal time',
		name4: 'Failure reason',
		type0: 'To be reviewed',
		type1: 'Audit succeeded',
		type2: 'Audit failed',
	},
	Login: {
		title: "Welcome Back",
		rem: 'Remeber the password',
		signIn: 'Sign In',
		signUp: 'Sign up',
		userName: "Username",
		mobile: "Mobile",
		password: "Login password",
		code: "Attach",
	},
	register: {
		title: "Create Account",
		registration: 'Encrypted registration',
		registration1: 'The password is stored in a decentralized way and cannot be retrieved after it is lost. Please remember your password. ',
		signIn: 'Sign In',
		signUp: 'Sign up',
		password: "Login password",
		payPassword: "Repeat password",
		inviteCode: "Invite code",
		code: "Attach",
		userName: "Username",
		name: 'Not received',
		name1: 'second',
		name2: 'Resend available'
	},
	changePwd: {
		name: 'Change Password',
		name1: 'New Password',
		name2: 'Old Password',
		name3: 'Repeat Password',
		name4: 'Confirm',
	},
	home: {
		name: "Register Wallet",
		name1: "Buy Currency",
		name2: "Real time award",
		name3: "register",
		name4: "Decentralization",
		name5: "address",
		name6: "platform",
		name7: "website",
		name8: "course",
		name9: "VERIFY",
		name10: "exchange",
		name11: "wallet",
		name12: "Exclusive customer service",
		name13: "currency",
		name14: "times",
		name15: "from",
		name16: "decentralization",
		name17: "amount",
		name18: 'Cash withdrawal',
		name19: 'Online',
		name20: 'Popular recommendations',
		gameTabs: ['All', 'Marbles'],
		menuList: ['Event News', 'Wallet', 'Withdrawal', 'Invitation'],
		navList: [{
				title: "Openness and transparency",
				secList: [
					"We defend the right of everyone to equity",
					"The block hash value is randomly generated based on the decentralized blockchain network, and anyone can query on the blockchain anytime, anywhere",
					"Truly ensure that the platform is a fair, just and open digital currency platform",
				]
			},
			{
				title: "Integrity management",
				secList: [
					"We are committed to ensuring the security of customers' information property",
					"No real name registration and no personal information binding",
					"There is no need to recharge the deposit and participate in the lottery by direct transfer",
					"Withdrawal application review is not required",
					"No restrictions on payment flow",
					"The platform fund pool can be checked at any time, and there is no need to worry about the rupture of the platform fund"
				]
			},
			{
				title: "High winning rate",
				secList: [
					"We pursue minimalist process / fast lottery",
					"Just a [decentralized] digital wallet and participate in the lottery immediately",
					"No restrictions on playing methods, strategies and equipment, and do whatever you want anytime, anywhere",
					"Automatically arrive within 10 seconds after winning the prize, and the game is uninterrupted",
				]
			},
			{
				title: "Exclusive customer service",
				secList: [
					"Explain one-on-one and reply efficiently.",
					"A variety of contact information for you to choose freely.",
				]
			}
		],
		ruleList: [
			"03.If the amount is lower than the minimum, you cannot participate in the lucky draw and the participating amount will not be returned",
			"04.If the minimum amount is higher than the minimum amount, you cannot participate in the lucky draw and refund the remaining amount after deducting double handling fees",
			"05.Only integer currency is supported to participate in the lucky draw, and the decimal part will be ignored automatically"
		],
		rule: "01.TRX the number of participants only supports ",
		rule1: "digital coins.",
		rule2: "02.USDT the number of participants only supports ",
		problemList: [{
				q: 'Q1：What is a block hash?',
				a: 'The block hash value is the DNA of a piece of data. Each block hash value is unique, random and unbreakable. You can view the introduction of Google hash value'
			},
			{
				q: 'Q2：What is blockchain?',
				a: 'In essence, it is a database or a blockchain that stores information. It has the characteristics of non forgery, leaving traces in the whole process, traceability, openness and transparency, collective maintenance and so on.'
			},
			{
				q: "Q3：Centralized wallet? Decentralized wallet? Why can't the centralized wallet query the transfer?",
				a: "For example: your wallet address is a, and you transfer 10 usdts to address e, but you can only query the transfer between a service provider's random ledger address B (or C or D, etc.) and E by using the transaction hash value. Similarly, e cannot know whether these 10 usdts are transferred out by you, because e can only see that it is the account book address B that transfers in 10 usdts to him. This is why the centralized wallet cannot query the real transfer information on the blockchain."
			},
			{
				q: 'Q4：Why do I win or not win in a row?',
				a: "Firstly, the events that fail to win the prize for many times in a row are small probability events. Secondly, winning and not winning the prize are random events, and the frequency of random events will tend to a stable value. In the case of non iteration, the probability of things appearing is equal for everyone. In this case, everyone is fair. At the same time, random events will comply with the law of large numbers (under the condition that the test remains unchanged, repeat the test many times, and the frequency of random events is similar to its definition of the law of large numbers). With the increasing transfer frequency, the probability of winning or not winning will tend to a stable value. So please continue to transfer. As the transfer frequency increases, the total probability of winning the prize will increase."
			},
			{
				q: 'Q5：Why is it delayed?',
				a: "In order to ensure the security of funds, blockchain technology will conduct multiple accounting, comparison and confirmation. So the calculation speed is slow, but the most important thing is security."
			},
			{
				q: 'Q6：Why are there so many usdt type options when transferring currency? Which one should I choose?',
				a: "Many well-known public chains around the world support usdt transfer, and lucky hash is based on wave field trc20 technology. Therefore, the transfer address of lucky hash belongs to usdt-trc20. You should choose usdt-trc20; If you choose another type, lucky hash will not receive your usdt, which will cause losses to you and lucky hash."
			},
			{
				q: 'Q7：What should I do if I transfer the wrong address or select the wrong currency type? Can I get it back?',
				a: "For example, when you transfer money to us, you should choose usdt-trc20, but if you choose other types of usdt-omni or usdt-erc20, you will lose money, which cannot be retrieved."
			},
		],
	},
	billOrder: {
		name: 'BillOrder',
		name1: 'Currency',
		name2: 'Original Balance',
		name3: 'Change Amount',
		name4: 'Amount after change',
		name5: 'Type',
		name6: 'Date',
		type0: 'Recharge commission',
		type1: 'Register lottery',
		type2: 'Invitation commission',
		type3: 'Withdrawal and withdrawal',
		type4: 'Reversal of withdrawal failure',
		type5: 'Win',
		type6: 'Recharge and entry',
		type7: 'Reversal of failed bets',
		type8: 'Event rewards',
		type9: 'Daily water return',
		type10: 'Betting',
		type11: 'Recharge and entry',
	},
	guessNew: {
		name: 'Recharege',
		name1: 'Record',
		name2: 'The last place of the big guess hash value, 0-7 is Small, 8-f is Big; 0, 2, 4, 6, 8, a, c, e are Even, 1, 3, 5, 7, 9, b, d, f are Odd.',
		name3: 'Balance',
		name4: 'Confirm Order',
		name5: 'Betting Options',
		name6: 'Click to enter the betting amount',
		name7: 'Total',
		name8: 'Determine',
		name9: 'Bet',
		name10: "FEATURES",
		name11: 'ROUND',
		name12: 'VERIFY',
		name13: 'Hash Value',
		name14: 'mean Big',
		name15: 'mean Small',
		name16: 'mean Odd',
		name17: 'mean Even',
		numList: ['Big', 'Small', 'Odd', 'Even', 'Big-Odd', 'Big-Even', 'Small-Odd', 'Small-Even'],
		name18: 'Stop betting',
		name19: 'Betting Success',
		name20: 'Back',
		name21: 'Result Detail',
		name22: 'Block',
		name23: 'Betting',
		name24: 'Result',
	},
	mine: {
		name: "Mine",
		name1: "Trading today",
		name2: "Profit today",
		name3: "Action",
		name4: "Subordinate member",
		name5: "flowing ",
		name6: "Close",
		name7: "people",
		name8: "award",
		name9: "amount",
		name10: 'Unfinished Flow',
		name11: 'Cumulative Deposit Amount',
		name12: 'Target',
		title: "Event News",
		tabNameList: ['Wallet', 'Withdrawal', 'Team', 'BillOrder', 'Record', 'Share',
			'Notification', 'Payment Password', 'Logout'
		],
	},
	order: {
		name: "Order",
		name1: "GameName",
		name0: 'issue number',
		name2: 'Order number',
		name3: 'result',
		name4: "Betting",
		name5: "Amount",
		name6: "Win",
		name7: "Date",
		name8: 'Big',
		name9: 'Small',
		name10: 'Odd',
		name11: 'Even',
		name12: 'Big-Odd',
		name13: 'Big-Even',
		name14: 'Small-Odd',
		name15: 'Small-Even',
	},
	share: {
		name: "Share",
		Invite: "Invite your contacts",
		Invite1: "or Friends and Earn Rewards",
		Refer: "Refer and Earn Rewards",
		Refer1: "Share your referal link and start earning",
		Refer2: "Copy Link",
		name1: "Invitation rules",
		name2: "Please share your invitation link and invite more friends to participate in this fun guessing game. While experiencing the joy of the game, you can also lie down and collect money!",
		name3: "You will get:",
		name4: "LV1 directly recommends a commission of 1% of the total transfer amount of users",
		name5: "LV2 indirectly recommends a commission of 0.6% of the total transfer amount of users",
		name6: "LV3 indirectly recommends a commission of 0.4% of the total transfer amount of users",
		name7: "If you directly invited 50 LV1 users, and your 50 LV1 users also invited their 50 friends to participate in the game, then you have 2500 LV2 users.",
		name8: "2500 LV2 users also invited 50 of their friends to participate in the game, so you have 125,000 LV3 users.",
		name9: "If everyone transfers 500 USDT per day on average, you can get:",
		name10: "(50×500)×1%+(2500×500)×0.6%+(125000×500)×0.4%=257750USDT",
	},
	appDown: {
		name: "Apple",
		name1: "Android",
	},
	trend: {
		name: "Trend",
		name1: "Last 10",
		name2: "Historical statistics",
		name3: "NUMBER",
		name4: "LETTER",
		name5: "The probability",
	},
	withdrawal: {
		name: "Withdrawal",
		name1: "amount of money",
		name2: "Commission withdrawal",
		name3: "CurrencyType",
		name4: "Withdrawal Amount",
		name5: "Address(Start with T)",
		name6: "Enter your Payment password",
		name7: "Confirm",
		name8: 'Set Payment password',
		name9: 'Input Payment password',
		name10: 'Set address And Set payment password',
	},
	betting: {
		name: "Recharge",
		name1: "The order is submitted successfully,please complete the payment within five minutes",
		name2: "copy",
		name3: "Paid,verification result",
		name4: 'My Balance',
		name5: 'Withdrawal',
		name6: 'Manual recharge',
		name7: 'Consult',
	},
	notify: {
		name: "Notification",
		name1: "close",
	},
	team: {
		name: "Team",
		name1: "Total Commission",
		name2: "Settled",
		name3: "Unsettled",
		name4: "First level",
		name5: "Amount",
		name6: "Ratio",
		name7: "Total bet",
		name8: "Commission",
		name9: "Second level",
		name10: "Third level",
	},
	lottery: {
		name: "Lottery",
		name1: "Rule",
		name2: "Number",
		name3: "All",
		name4: "COUNT",
		name5: "TOTAL",
		name6: "Pay on Win",
		name7: "Bet",
		name8: "ROUND",
		name9: "RESULT",
		name10: "LAST DIGIT",
		name11: "Betting Content",
		name12: "State",
		name13: "VERIFY",
		tabList: ['ROADMAP', 'All bets', 'My bet'],
		name14: "ShellHash.com",
		name15: "Please login Wallet",
		name16: "Ok",

		name17: "Recharge",
		name18: "Balance",
		name19: "Insufficient balance, please recharge",

		rule: "Lottery game description",
		rule1: "1. The lottery is drawn every 180 seconds. It requires users to have a fast payment method, so currently the lottery only supports playing games using a wallet browser. (It is recommended that users use TronLink, imToken, TokenPocket browsers to log in to ",
		rule2: " to play!)",
		rule3: "2. Blockchain is a distributed ledger system. The bookkeeper of the blockchain system collects the newly generated transactions in the blockchain network, verifies the legitimacy of these transactions, then packs these transactions in a block, records them on the ledger as a new page, and then records the account Pages are broadcast to the entire blockchain network. TRON blocks are formed every 3 seconds and each block has a unique hash value! The lottery will be based on the 5-digit mantissa of a TRON block hash every 180 seconds!",
		rule4: "3. Users can view all TRON block hashes at https://tronscan.io/",
		rule5: "4. Users can make betting guesses on the 5 mantissas respectively! The minimum single bet amount is 10TRX!",
		rule6: "5. We will stop all users' betting behavior 30 seconds before each draw! That is, at the 150th second of each round, we will ban bets! Therefore, the user only has 150 seconds to place a bet. If you place a bet after 150 seconds, the bet will be considered void and the system will automatically refund your bet!",
		rule7: "0, 1, 2, 3, 4 are low; 5, 6, 7, 8, 9 are high.",
		rule8: "0, 2, 4, 6, 8 are even numbers; 1, 3, 5, 7, and 9 are odd numbers.",
		rule9: "High, Low, Even, Odd odds are 1.9, which means if you bet 100 and win, you will get 190 stake and profit. The numerical odds are 9.5, which means that if you bet 1, bet 100 and hit the jackpot, you will get your stake and profit of 950.",
	},
	guess: {
		name: "Trend",
		name1: "Game Rule",
		name2: "Win rate",
		name3: "Odds",
		name4: "Confirm",
		name5: "Win Times",
		name6: "Lose Times",
		name7: "Total Profit",
		name8: "Order Confirmation",
		name9: "Your betting item",
		name10: "Your betting currency",
		name11: "Your betting amount",
		name12: "Cancel",
		name13: "Ok",
		name14: "Please login Wallet",
		name15: "Recharge",
		name16: "Balance",
		name17: "Insufficient balance, please recharge",
		rule1: "Hash guessing is a new type of guessing smart contract based on the TRX public chain. Users getrewards by guessing the last character of the hash value generated during the transactionpayment process.",
		rule2: "1. The last character of the hash value will be (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, a, b, c, d, e, f)16 characters of which one of! The user can select one or more guess results, and the system will automatically calculate the reward multiple! If you choose only one result and win theprize, you can get up to 15 times the transfer amount as a reward! The more results you choose,the fewer reward multiples!",
		rule3: "2. After you select the guess result and confirm the order submission, the system will show you aTRX transfer address, and you need to pay any amount of TRX to this address.After the payment iscompleted,the hash value of the payment order will be generated immediately!",
		rule4: "3. When you have completed the payment, please return to ShellHash and click the paymentverification button. If you win, the smart contract will immediately return the winning amountto the TRX address you paid.",
		rule5: "4. It is recommended to use Tronlink, the official wallet of TRON, for guessing games, which canimprove your experience in the game.",
		rule6: "Important: Users complete TRX purchases on exchanges! In fact, the TRX purchased by the user is stored in the public address of the exchange before it leaves the exchange!",
		rule7: "When you transfer to ShellHash, what is transferred to ShellHash is the public address of the exchange!",
		rule8: "After winning the prize, ShellHash will automatically return the prize to the official account of the exchange!",
		rule9: "So the user will lose the bonus because of this!",
		rule10: "So be sure to transfer the TRX from the exchange to your wallet, and then bet with your wallet address! This way, the bonus will also be returned to your wallet in the same way!",
	},
};

export default engLang