// 对缓存的操作
export default {
	// localStorage
	// set
	setLocalItem(name,value){
		let content = value;
		if(!name) return
		if(typeof value != 'string'){
			content = JSON.stringify(value)
		}
		window.localStorage.setItem(name,content)
	},
	// get
	getLocalItem(name){
		if(!name) return null
		return window.localStorage.getItem(name)
	},
	// romove
	romoveLocalItem(name){
		if(!name) return
		window.localStorage.removeItem(name)
	},
	// sessionStorage
	// set
	setSionItem(name,value){
		let content = value;
		if(!name) return
		if(typeof value != 'string'){
			content = JSON.stringify(value)
		}
		window.sessionStorage.setItem(name,content)
	},
	// get
	getSionItem(name){
		if(!name) return null
		return window.sessionStorage.getItem(name)
	},
	// remove
	removeSionItem(name){
		if(!name) return
		window.sessionStorage.removeItem(name)
	},
	// removeAll
	removeAllItem(){
		window.localStorage.clear()
		window.sessionStorage.clear()
	},
}
